import {
  RESET,
  SET_USER_TOKEN,
} from "../actions";

export const initialState = {
  snackBar: {
    open: false,
    variant: "success",
    message: ""
  },
  user: null,
  userToken: null,
  designData: null,
  filter:{city:"BNG_KA_IN"},
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return {
        ...initialState,
      }
    case SET_USER_TOKEN: {
      return {
        ...initialState,
        userToken: action.token
      }
    }
    default:
      return state;
  }
};

export default app;
