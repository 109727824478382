import React from 'react';
import logo from '../../../assets/images/Logo.png';
import './styles.scss';

const HeadLogo = () => (
    <div className='head-logo-wrapper'>
        <img className='head-logo' src={logo} alt={'head-logo'} />
    </div>
);

export default HeadLogo;