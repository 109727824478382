import React, { useEffect, useRef, useState } from 'react';
import {Wrapper} from '@googlemaps/react-wrapper';

interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}

const CustomMap = ({
    API_KEY,
    center= {
        lat: 12.970333852780856, 
        lng: 77.59156007910155
    },
    zoom = 10,
    children,
}) => {
  return (
    <Wrapper apiKey={API_KEY}>
      <Map latitude={center.lat} longitude={center.lng} zoom={zoom}>{children}</Map>
    </Wrapper>
  );
};

const Map = ({latitude, longitude, zoom, children}) => {
  const ref = useRef(null);
  const [map, setMap] = useState(null);
  const [lat, setLat] = useState(latitude);
  const [lng, setLng] = useState(longitude);

  useEffect(() => {
    new google.maps.Marker({
      position: {lat: latitude, lng: longitude},
      map,
    });
  }, [ref, map, latitude, longitude]);
  useEffect(() => {
    if (ref.current && !map) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          center: {
            lat: latitude ?? 0,
            lng: longitude ?? 0,
          },
          zoom: zoom ?? 11,
          fullscreenControlOptions: {
            
          }
        })
      );
    } else if (ref.current && map && (lat !== latitude || lng !== longitude)) {
      setMap(
        new window.google.maps.Map(ref.current, {
          zoomControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          center: {
            lat: latitude ?? 0,
            lng: longitude ?? 0,
          },
          zoom: zoom ?? 11,
          fullscreenControlOptions: {
            
          }
        })
      );
      setLat(latitude);
      setLng(longitude);
    }
  }, [ref, map, lng, lat, latitude, longitude]);

  return <div ref={ref} style={{height: '100%', width: '100%'}}>{children}</div>;
};

export default CustomMap;