import './App.css';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from './redux/store';
import { RootRouter } from './router';
import AppContext from './utils/appContext';

function App() {
  return (
    <AppContext.Provider> 
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <RootRouter />
        </PersistGate>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
