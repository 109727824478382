export const SESSION_EXPIRED_TITLE = 'Session Expired';
export const PERMISSION_REQUIRED_TITLE = 'Permission Required';
export const TRY_AGAIN_TITLE = 'Try Again Later';
export const ERROR_TITLE = 'Error';
export const SESSION_EXPIRED_DESC =
  'Your session is expired, kindly re-login & proceed further.';
export const PERMISSION_REQUIRED_DESC =
  'You dont have permission to view this page! Kindly check your permission(s) with team & then try again.';
export const TRY_AGAIN_DESC =
  'There seems to be an issue with the action you are trying to perform, kindly try again later or contact the technical team.';
