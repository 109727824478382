import { Card, Checkbox, Col, Input, InputNumber, Row, Select } from 'antd';
import React, { useState } from 'react';
import { toNumber } from 'lodash';
import { COMPOUND_WALL_TYPES, EXTRA_GATE_TYPES, FLOOR_PACKAGES, MAIN_GATE_TYPES, NO_OF_UNITS, TYPE_OF_UNITS } from '../../../constants';
import Icon from '@ant-design/icons/lib/components/Icon';

const FloorUnit = ({
    floorUnit,
    removeUnit,
    mutateUnit,
    cityPackages,
    id,
}) => {
    const [unit, setUnit] = useState(floorUnit);
    const [floorArea, setFloorArea] = useState(floorUnit.floorUnitArea);
  
    const mutateProperty = (prop, value) => {
        mutateUnit(id, {
            ...unit,
            [prop]: value,
        });
        setUnit({
            ...unit,
            [prop]: value,
        });
  
    };
  
  
    return (
        <>
      <Card>
            <label style={{ fontWeight: '600' }}>
                Type of unit (Unit no: {id + 1})
            </label>
            <div>
                <Select
                    style={{
                        color: 'black',
                        border: '0px solid transparent',
                        width: '100%', 
                    }}
                    value={unit.typeOfFloorUnit}
                    onChange={(val) => mutateProperty('typeOfFloorUnit', val)}
                    placeholder="Select here"
                >
                    {(TYPE_OF_UNITS).map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <hr className='hr-style' />

            <label style={{ fontWeight: '600' }}>No of units</label>
            <Select
                style={{
                    color: 'black',
                    border: '0px solid transparent',
                    width: '100%', 
                }}
                value={unit.noOfFloorUnits}
                onChange={(val) => mutateProperty('noOfFloorUnits', val)}
                placeholder="Select here"
            >
                {(new Array(NO_OF_UNITS).fill(0)).map((option, id) => (
                    <Select.Option key={id} value={id + 1}>
                        {(id + 1).toString()}
                    </Select.Option>
                ))}
            </Select>
            <hr className='hr-style' />

            <label style={{ fontWeight: '600' }}>Floor package</label>
            <Select
                style={{
                    color: 'black',
                    border: '0px solid transparent',
                    width: '100%', 
                }}
                value={unit.floorUnitPackage}
                onChange={(val) => mutateProperty('floorUnitPackage', val)}
                placeholder="Select here"
            >
                {(cityPackages).map((option) => (
                    <Select.Option key={option.packageUniqueId} value={option.packageUniqueId}>
                        {option.packageDescription}
                    </Select.Option>
                ))}
            </Select>
            <hr className='hr-style' />

            <label style={{ fontWeight: '600' }}>Floor Area (sqft)</label>
            <InputNumber
                style={{ width: '100%' }}
                value={unit.floorUnitArea}
                type="number"
                onBlur={(value) => mutateProperty('floorUnitArea', floorArea)}
                onChange={(value) => setFloorArea(value)}
            />
            <hr className='hr-style' />

            <label style={{ fontWeight: '600' }}>Unit description</label>
            <Input
                placeholder={'Enter description'}
                value={unit.floorUnitDescription}
                disabled
            />
            <hr className='hr-style' />

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <label style={{ color: 'red', padding: '5px 10px', textAlign: 'center', border: '0px solid transparent', borderRadius: '5px' }} onClick={() => removeUnit()}>
                    REMOVE FLOOR UNIT
                </label>
            </div>
      </Card>
    </>
    );
};

export default FloorUnit;