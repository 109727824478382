import React, { useEffect, useState } from 'react';
import { isEmpty, findIndex } from 'lodash';
import QuotationPlotDetails from '../../components/molecules/QuotationPlotDetails';
import { FLOOR_PACKAGES, TYPE_OF_UNITS } from '../../constants';
import api from '../../utils/apiClient';
import './styles.scss';
import QuotationNoOfFloors from '../../components/molecules/QuotationNoOfFloors';
import SamePackage from '../../components/molecules/SamePackage';
import QuotationBasement from '../../components/molecules/QuotationBasement';
import QuotationCompoundWall from '../../components/molecules/QuotationCompoundWall';
import { Card, message } from 'antd';
import QuotationFloor from '../../components/molecules/QuotationFloor';
import { HandleServerError } from '../../utils/serverErrorHandler';

const QuotationForm = ({
  meeting = {},
  userDetails = {},
  setLoading,
  onError,
  onSuccess,
  onBack,
}) => {

  const [cityPackages, setCityPackages] = useState([]);
  useEffect(() => {
    if (meeting.cityId) {
      api.get(`/pricingengine/api/pricingengine/package/constructionPackages/${meeting.cityId}`, {}, {
        Authorization: `Bearer ${userDetails.token}`
      }).then((res) => {
        setCityPackages(res.data.responseData);
      }).catch((e) => {
        HandleServerError(e)
      });;
    }
  }, [meeting, meeting.cityId]);
  const baseUnit = {
    typeOfFloorUnit: TYPE_OF_UNITS[0].value,
    noOfFloorUnits: 1,
    floorUnitArea: 0,
    floorUnitPackage: cityPackages && cityPackages[0] ? cityPackages[0].packageUniqueId : null,
    floorUnitDescription: '1 units of ' + TYPE_OF_UNITS[0].value,
  };
  const [noOfQuotations, setNoOfQuotations] = useState(0);
  const [quotationData, setQuotationData] = useState({
    length: 0,
    breadth: 0,
    builtUpArea: 0,
    constructionUnits: [],
    samePackage: false,
    anyduplex: false,
    floorQuotes: [{ floorUnits: [baseUnit], floorNumber: 0, collapsed: true }],
  });

  const mutateProperty = (prop, value) => {
    setQuotationData({
      ...quotationData,
      [prop]: value,
    });
    baseUnit.floorUnitArea = quotationData.breadth * quotationData.length * 0.8;
  };

  const setNoOfFloors = (count) => {
    let floorsData = [];

    for (let i = 0; i < count; i++) {
      const floor = {
        floorUnits: [baseUnit],
        floorNumber: i,
        collapsed: true,
      };

      floorsData.push(floor);
    }

    mutateProperty('floorQuotes', floorsData);
  };

  const mutateFloorsData = (id, floorData) => {
    let tempUnits = quotationData.floorQuotes.slice();

    tempUnits[id] = floorData;
    mutateProperty('floorQuotes', tempUnits);
  };

  const validateQuotation = (data) => {
    if (data.length === 0 || data.breadth === 0) {
      message.error('Enter plot length and breadth');
      return false;
    } else if (quotationData.samePackage && (!data.mainPackage || isEmpty(data.mainPackage))) {
      message.error('Select main package!');
      return false;
    }
    return true;
  };
  const setSamePackage = (packageName) => {
    let tempFloors = quotationData.floorQuotes.slice();

    tempFloors.forEach((item) =>
      item.floorUnits.forEach((unit) => (unit.floorUnitPackage = packageName)),
    );

    mutateProperty('floorQuotes', tempFloors);
    baseUnit.floorUnitPackage = packageName;
  };

  const modifyConstructionUnit = (typeOfUnit, value) => {
    let id = findIndex(quotationData.constructionUnits, {
      typeOfUnit: typeOfUnit,
    });

    let units = [...quotationData.constructionUnits];

    if (id === -1) {
      units.push(value);
    } else {
      units[id] = value;
    }

    mutateProperty('constructionUnits', units);
  };

  const submitSpecs = () => {
    setLoading(true);
    let data = {
      ...quotationData,
      srId: meeting.srId,
      builtUpArea: quotationData.length * quotationData.breadth * 0.8,
      plotArea: quotationData.breadth * quotationData.length,
      noOfFloors: quotationData.floorQuotes.length,
    };
    if (!validateQuotation(data)) {
      setLoading(false);
      return;
    }

    api
      .post(
        '/pricingengine/api/quotation/v1/create/' + userDetails.employeeUserId,
        {},
        data, {
        Authorization: `Bearer ${userDetails.token}`
      }
      )
      .then((res) => {
        onSuccess('Quotation created');
        setLoading(false);
        onBack();
      })
      .catch((error) => {
        setLoading(false);
        HandleServerError(error)
      });
  };

  useEffect(() => {
    setLoading(true);
    if(meeting.srId){
      api
        .get('/crmBackendService/api/crm/servReq/quotations/' + meeting.srId, {}, {
          Authorization: `Bearer ${userDetails.token}`
        })
        .then((res) => {
          setLoading(false);
          setNoOfQuotations(res.data?.responseData?.length);
        })
        .catch((e) => HandleServerError(e));
    }
  }, [meeting]);
  return (
    <div>
      <div>
        <label>{`No. of created quotations: ${noOfQuotations}`}</label>
      </div>
      <div>
        <h5>Plot Details</h5>
        <QuotationPlotDetails
          length={quotationData.length}
          breadth={quotationData.breadth}
          mutateProperty={mutateProperty}
          builtUpArea={quotationData.builtUpArea}
        />
        <hr className='hr-style' />


        <h5>No of floors</h5>
        <QuotationNoOfFloors setFloorInData={setNoOfFloors} />
        <hr className='hr-style' />

        <SamePackage
          cityPackages={cityPackages}
          setSamePackage={setSamePackage}
          mutateProperty={mutateProperty}
        />
        <hr className='hr-style' />

        <QuotationBasement
          modifyConstructionUnit={modifyConstructionUnit}
        />
        <hr className='hr-style' />


        <QuotationCompoundWall
          modifyConstructionUnit={modifyConstructionUnit}
        />
        <hr className='hr-style' />

        <Card>
          {quotationData.floorQuotes.map((item, id) => (
            <QuotationFloor
              floor={item}
              key={Math.random()}
              baseUnit={baseUnit}
              mutateFloorsData={mutateFloorsData}
              cityPackages={cityPackages}
              id={id}
              onSuccess={onSuccess}
              onError={onError}
            />
          ))}
        </Card>

        <button
          style={{ backgroundColor: '#f15a29', color: 'white', padding: '10px', textAlign: 'center', border: '0px solid transparent', borderRadius: '5px' }}
          onClick={() => submitSpecs()}>
          Submit specifications
        </button>
      </div>
    </div>
  );
}
export default QuotationForm;