import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react';
import './styles.scss';

const FormHeader = ({
    headerTitle = '',
    headerLeftClick = () => {},
}) => (
    <div className='sticky-head-wrapper'>
        <div className='form-head-wrapper'>
            <ArrowLeftOutlined
                className='header-left-icon'
                onClick={headerLeftClick}
            />
            <h3 className='header-title'>{headerTitle}</h3>
        </div>
    </div>
);

export default FormHeader;