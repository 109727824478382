import { Col, Input, InputNumber, Row } from 'antd';
import React from 'react';
import { toNumber } from 'lodash';

const QuotationPlotDetails = ({
  length,
  breadth,
  builtUpArea,
  mutateProperty,
}) => {
    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ maxWidth: '48%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={{ fontWeight: '600' }}>Length (ft)</label>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={length.toString()}
                            type="number"
                            onChange={(value) => mutateProperty('length', (value || 0))}
                        />
                    </div>
                </div>
                <div style={{ maxWidth: '48%' }}>
                    <div>
                        <label style={{ fontWeight: '600' }}>Breadth (ft)</label>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={breadth.toString()}
                            type="number"
                            onChange={(value) => mutateProperty('breadth', (value || 0))}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ maxWidth: '48%' }}>
                    <div>
                        <label style={{ fontWeight: '600' }}>Plot area (sqft)</label>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={(length * breadth).toFixed(2)}
                            type="number"
                            disabled
                        />
                    </div>
                </div>
                <div style={{ maxWidth: '48%' }}>
                    <div>
                        <label style={{ fontWeight: '600' }}>Built up Area</label>
                        <InputNumber
                            style={{ width: '100%' }}
                            value={(length * breadth * 0.8).toFixed(2)}
                            type="number"
                            disabled
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuotationPlotDetails;