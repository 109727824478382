import React from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import MeetingDetails from "../screens/meeting-details";
import QuestionForm from "../screens/question-form";
import CallAnalytics from "../screens/call-analytics";
import { message } from "antd";

const MainRouter = ({ userToken }) => {
  message.config({
    maxCount: 1,
  })
  return (
    <BrowserRouter>
      {true ? (
        <Routes>
            <Route
                path=""
                element={<MeetingDetails />}
            />
            <Route
                path="/form"
                element={<QuestionForm />}
            />
            <Route
                path="/call-analytics"
                element={<CallAnalytics />}
            />
        </Routes>
      ) : (
        <Routes>
            <Route
                path={'/404'}
                render={() => (<div>Route Not Found!</div>)}
            />
        </Routes>
        )}
    </BrowserRouter>
  );
};

const mapStateToProps = state => {
  return {
    userToken: state.common.userToken,
  };
};

const mapDispatchToProps = state => {
  return {};
};

export const RootRouter = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainRouter);
