import React, { useEffect, useRef, useState } from 'react';
import GoogleMap from 'google-map-react';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import S3 from "react-aws-s3";
import { FileUpload } from '../../utils';
import { Button, DatePicker, Form, Input, message, Radio, Rate, Select, Upload, InputNumber, TimePicker, Spin } from "antd";
import moment, { invalid } from 'moment';
import api from '../../utils/apiClient';
import { FullscreenOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import CustomMap from '../../components/atoms/CustomMap';
import { LOCATION_QUESTION_TYPES } from '../../constants';
import { HandleServerError } from '../../utils/serverErrorHandler';
import LocationPickerModal from '../../components/cells/location-picker-modal';
import { isArray } from 'lodash';
window.Buffer = window.Buffer || require("buffer").Buffer;
dayjs.extend(weekday)
dayjs.extend(localeData)
const tailWrapperLayout = {
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
    },
};
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const DynamicForm = ({
    initialForm = false,
    disableForm = false,
    questions = [],
    apiToken = '',
    formId,
    formDetails = {},
    meeting = {},
    onSubmit,
    moveNext,
    onBack,
    onSuccess,
    onError,
    userDetails = {},
    setSubmitLoading,
    submitLoading
}) => {
    let formRef = useRef();
    const [ReactS3Client, setReactS3Client] = useState({});
    const [loader, setLoader] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    const [nextEvent, setNextEvent] = useState(null);
    const [locationPickerField, setLocationPickerField] = useState(null);
    const [isLocationPickerModalVisible, setIsLocationPickerModalVisible] = useState(false);
    const [plotLocation, setPlotLocation] = useState({
        lat: -1,
        lng: -1,
        address: "",
    });
    const [disableSubmitButton, setDisableSubmitButton] = useState(false)
    const [formFieldValues, setFormFieldValues] = useState(formId !== '6363a5b183d40f070eab79d3' ? { ...formDetails, dob: moment(new Date) } : {});
    const [locationValues, setLocationValues] = useState({});
    const [extraInfo, setExtraInfo] = useState({})
    const [defaultFileList, setDefaultFileList] = useState([]);
    const [cityPackages, setCityPackages] = useState([]);
    const [subOptions, setSubOptiions] = useState([]);
    const [actionOptions, setActionOptions] = useState([]);
    const [fetchedQuesIds, setFetchedQuesIds] = useState([]);
    const [derivedQues, setDerivedQues] = useState([]);
    const [derivedQuesIdsDynamic, setDerivedQuesIdsDynamic] = useState({});
    const removeDerivedQue = (qk) => {
        let queList = derivedQues;
        let derivedQueIds = fetchedQuesIds;
        if (qk.subQuestionId) {
            qk.subQuestionId.length !== 0 && qk.subQuestionId.map((item) => {
                queList = queList.filter((i, v) => i.id !== item);
                derivedQueIds = derivedQueIds.filter((i, v) => i !== item);
            });
        }
        setFetchedQuesIds(derivedQueIds);
        setDerivedQues(queList);
    };

    const removeDerivedDynamic = (subQuestionIds,parentId) => {
        let queList = derivedQues;
        let derivedQueIds = fetchedQuesIds;
        if (subQuestionIds) {
            subQuestionIds.length !== 0 && subQuestionIds.map((item) => {
                queList = queList.filter((i, v) => i.id !== item);
                derivedQueIds = derivedQueIds.filter((i, v) => i !== item);
            });
        }
        derivedQuesIdsDynamic?.[parentId]?.forEach(item=>{
            queList = queList.filter((i, v) => i.id !== item);
            derivedQueIds = derivedQueIds.filter((i, v) => i !== item);  
        })
        setFetchedQuesIds(derivedQueIds);
        setDerivedQues(queList);
        return {derivedQueIds, queList}
    };

    let isReactS3ClientSet = false;
    useEffect(() => {
        if(isReactS3ClientSet) return;
        isReactS3ClientSet = true;
        api.get('/pmService/api/v1/s3/getAwsCredentials', {}, {
            Authorization: `Bearer ${apiToken}`
        }).then((res) => {
            setReactS3Client(new S3({
                bucketName: res.data.responseData.bucket,
                region: res.data.responseData.region,
                accessKeyId: res.data.responseData.accessKey,
                secretAccessKey: res.data.responseData.secretKey,
                successActionStatus: res.data.successActionStatus,
            }));
        });
    }, []);
    let isCityPackagesSet = false;
    useEffect(() => {
        if(isCityPackagesSet) return;
        isCityPackagesSet = true;
        if (meeting.cityId) {
            api.get(`/pricingengine/api/pricingengine/package/constructionPackages/${meeting.cityId}`, {}, {
                Authorization: `Bearer ${apiToken}`
            }).then((res) => {
                setCityPackages(res.data.responseData);
            }).catch((e) => {
                HandleServerError(e)
            });;
        }
    }, [meeting?.cityId]);

    useEffect(() => {
        setDerivedQues(sortByKey([...questions], 'priority'));
    }, [questions, formId]);

    useEffect(() => {
        let fetchedList = [];
        if (Object.keys(formFieldValues).length !== 0 && formId !== '6363a5b183d40f070eab79d3') {
            derivedQues.map((qk) => {
                // if (checkConditionForDerived(qk, formFieldValues[qk.serverValue])) {
                //     fetchedList = [...fetchedList, ...qk.subQuestionId];
                //     console.log('fetchedList', fetchedList)
                // }
                if (qk.inputType === 'MULTI_SELECT' ? isArray(formFieldValues[qk.serverValue]) && formFieldValues[qk.serverValue].includes(qk.subQuesConditionValue) : (((formFieldValues[qk.serverValue] === (
                    (qk.inputType === 'SELECT')
                        ? qk.subQuesConditionValue
                        : (qk.inputType === 'RADIO')
                            ? (qk.subQuesConditionValue === 'true' || qk.subQuesConditionValue === 'false')
                                ? JSON.parse(qk.subQuesConditionValue)
                                : qk.subQuesConditionValue
                            : (typeof JSON.parse(qk.subQuesConditionValue) === 'string'
                                ? qk.subQuesConditionValue
                                : JSON.parse(qk.subQuesConditionValue))
                )) && (qk.subQuestionId && qk.subQuestionId.length !== 0)) || (qk.subQuesConditionValue === null && (qk.subQuestionId && qk.subQuestionId.length !== 0))
                )) {
                    if(formFieldValues[qk.serverValue])
                        fetchedList = [...fetchedList, ...qk.subQuestionId]
                } else if(qk.allowMultipleCondition && formFieldValues[qk.serverValue]){
                    qk?.subQuestionMap?.filter(t=>t.condition==formFieldValues[qk?.serverValue])?.[0]?.questionIds.forEach(questionId=>{
                        getDerivedQuestions(qk, formFieldValues[qk.serverValue], fetchedQuesIds, derivedQues)
                    })
                }
            });
        }
        // setLoader(true)
        fetchDerivedQuestions(fetchedList);
    }, [formFieldValues, derivedQues, formId]);

    const handleChange = (fileList, field) => {
        if (!Array.isArray(fileList)) {
            setDefaultFileList([]);
        }
        const isLt5M = fileList.file.size / 1024 / 1024 > 5
        if (isLt5M) {
            fileList.fileList.pop()
        } else {
            setDefaultFileList(fileList.fileList);
        }
    };
    const uploadFiles = ({ file, onProgress, onSuccess }, field) => {
        const isLt5M = file.size / 1024 / 1024 > 5;
        if (isLt5M) {
            message.error('File Size should be less than 5mb!');
            return false
        } else {
            // const fileUpload = new FileUpload(file);
            let newFileName = file.name;
            let formValues = formRef.current.getFieldsValue();
            ReactS3Client.uploadFile(file, newFileName)
                .then((data) => {
                    if (data.status === 204) {
                        const newFile = {
                            ...file,
                            status: data.status,
                            url: data.location,
                        };
                        formRef.current.setFieldsValue({
                            ...formRef.current.getFieldsValue(),
                            [field]: [...(formValues[field] || []), data.location]
                        });
                        setFormFieldValues({
                            ...formFieldValues,
                            [field]: [...(formValues[field] || []), data.location],
                        });
                        onSuccess({ url: data.location }, newFile);
                    }
                })
                .catch((err) => {
                    HandleServerError(err)
                    // console.log("err from reacts3:", err);
                });
        }
    };
    const uploadButton = (
        <div key={Math.random() * 10}>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const getActionOptions = (val) => {
        let targetOptions = [];
        derivedQues.map((qk) => {
            if (qk.serverValue === 'osMeetingOutcome') {
                (val && val.map((item) => {
                    let target = (qk.options || []).filter((i, v) => i.uniqueId === item);
                    targetOptions.push({
                        ...target[0]
                    })
                }))
            }
        });
        return targetOptions;
    };

    const handleNullInitialValue = (qk) => {
        let maxDate = new Date();
        // maxDate = new Date(maxDate.setFullYear(maxDate.getFullYear() - 18))
        switch (qk.inputType) {
            case 'TEXT': return '';
            case 'INTEGER': return '';
            case 'DECIMAL': return '';
            case 'SELECT': return null;
            case 'MULTI_SELECT': return [];
            case 'DOB': return moment(maxDate);
            case 'DATE': return moment(new Date())
            default: return null;
        }
    }

    const getInitialValue = (field, qk) => {
        if (Array.isArray(formFieldValues)) {
            const formValue = formFieldValues[0];
            if (qk.inputType === "DATE" || qk.inputType === "DOB") {
                return moment(formValue?.[field])
            }
            return formValue?.[field];
        } else {
            if (qk.inputType === "DATE" || qk.inputType === "DOB") {
                return (formFieldValues?.[field]) ? dayjs(moment(formFieldValues?.[field])) : formFieldValues?.[field] === false ? false : handleNullInitialValue(qk)
            }
            else {
                return (formFieldValues?.[field]) ? formFieldValues?.[field] : formFieldValues?.[field] === false ? false : handleNullInitialValue(qk)
            }
        }
    }

    useEffect(() => {
        if (formId !== '6363a5b183d40f070eab79d3') {
            setFormFieldValues(formDetails);
            formRef.current.setFieldsValue({
                ...formFieldValues,
                ...formDetails,
            });
        } else {
            setExtraInfo(formDetails);
        }
    }, [formDetails, formRef])

    const shouldLocationBeVisible = () => {
        // console.log('nextEvent', nextEvent);
        // console.log('LOCATION_QUESTION_TYPES.includes(nextEvent)', LOCATION_QUESTION_TYPES.includes(nextEvent));
        let queExists = false;
        if (LOCATION_QUESTION_TYPES.includes(nextEvent)) {
            queExists = true;
        }
        if (nextEvent && queExists) {
            return true;
        }
        return true;
    }
    const getMeetingLocation = () => {
        let meetingType = '';
        formFieldValues.osMeetingOutcome && Array.isArray(formFieldValues.osMeetingOutcome) && formFieldValues.osMeetingOutcome.map((item) => {
            if (LOCATION_QUESTION_TYPES.includes(item)) {
                meetingType = item;
            }
        });
        switch (meetingType) {
            case "MEETING_FOLLOWUP_OFFICE": return {
                latitude: -1,
                longitude: -1,
            };
            case "MEETING_FOLLOWUP_CUSTOMER_LOCATION": return (userDetails.currentLocation || {
                latitude: -1,
                longitude: -1
            });
            case "MEETING_PLOT_LOCATION": return {
                latitude: extraInfo && extraInfo.plotInformation && extraInfo.plotInformation.plotLocationCoordinates && extraInfo.plotInformation.plotLocationCoordinates.latitude ? extraInfo.plotInformation.plotLocationCoordinates.latitude : -1,
                longitude: extraInfo && extraInfo.plotInformation && extraInfo.plotInformation.plotLocationCoordinates && extraInfo.plotInformation.plotLocationCoordinates.longitude ? extraInfo.plotInformation.plotLocationCoordinates.longitude : -1,
            };
            default: return {
                latitude: -1,
                longitude: -1
            };
        }
    }
    useEffect(() => {
        setNextEvent(null);
    }, [formFieldValues.osMeetingOutcome])

    useEffect(() => {
        const locationQueId = '6364627a83d40f070eab79e0';
        let queList = derivedQues;
        let derivedQueIds = fetchedQuesIds;
        if (!shouldLocationBeVisible() && formId === '6363a5b183d40f070eab79d3') {
            let payload = formRef.current.getFieldsValue();
            delete payload.meetingLocation;
            let tempFieldValues = formFieldValues;
            delete tempFieldValues.meetingLocation;
            formRef.current.setFieldsValue({
                ...payload,
                ...tempFieldValues
            });
            setFormFieldValues({
                ...payload,
                ...tempFieldValues
            })
        } else if (shouldLocationBeVisible() && formId === '6363a5b183d40f070eab79d3') {
            formRef.current.setFieldsValue({
                ...formRef.current.getFieldsValue(),
                ...formFieldValues,
                meetingLocation: null,
            });
            setFormFieldValues({
                ...formRef.current.getFieldsValue(),
                ...formFieldValues,
                meetingLocation: null
            })
            return;
        } else {
            return;
        }
    }, [nextEvent]);



    const sortByKey = (array, key) => {
        return array.sort((a, b) => {
            var x = a[key]; var y = b[key];
            return (x < y) ? -1 : ((x > y) ? 1 : 0);
        });
    }

    const fetchDerivedQuestions = (derivedList) => {
        const derivedQueList = [];
        const values = formRef.current.getFieldsValue();
        let fetchedList = [];
        let fetchedQueList = [];
        derivedList?.map((item, idx) => {
            if (fetchedQuesIds && !fetchedQuesIds.includes(item)) {
                api.get(`/spinozaService/api/v1/osMeeting/question/${meeting.cityId}/${item}`, {}, { Authorization: `Bearer ${userDetails.token}` })
                    .then((res) => {
                        setLoader(false)
                        if (item === '6364627a83d40f070eab79e0' && formId === '6363a5b183d40f070eab79d3' && shouldLocationBeVisible()) {
                            fetchedList = [...fetchedList, item];
                            fetchedQueList = [...fetchedQueList, res.data.responseData];
                            if (fetchedQuesIds.includes(derivedList[idx - 1]) || (derivedList.length === 1 && !fetchedQuesIds.includes(derivedList[0]))) {
                                setFetchedQuesIds([...fetchedQuesIds, item]);
                                setDerivedQues(sortByKey([...derivedQues, res.data.responseData], 'priority'));
                            } else {
                                setFetchedQuesIds([...fetchedQuesIds, ...fetchedList]);
                                setDerivedQues(sortByKey([...derivedQues, ...fetchedQueList], 'priority'));
                            }
                            derivedQueList.push(res.data.responseData);
                            formRef.current.setFieldsValue({
                                ...formRef.current.getFieldsValue(),
                                ...values,
                                ...formFieldValues,
                                [res.data.responseData.serverValue]: null,
                            })
                        } else if (item === '6364627a83d40f070eab79e0' && !shouldLocationBeVisible()) {
                            return;
                        } else {
                            fetchedList = [...fetchedList, item];
                            fetchedQueList = [...fetchedQueList, res.data.responseData];
                            if (fetchedQuesIds.includes(derivedList[idx - 1])) {
                                setFetchedQuesIds([...fetchedQuesIds, item]);
                                setDerivedQues(sortByKey([...derivedQues, res.data.responseData], 'priority'));
                            } else {
                                setFetchedQuesIds([...fetchedQuesIds, ...fetchedList]);
                                setDerivedQues(sortByKey([...derivedQues, ...fetchedQueList], 'priority'));
                            }
                            derivedQueList.push(res.data.responseData);
                            formRef.current.setFieldsValue({
                                ...formRef.current.getFieldsValue(),
                                ...values,
                                ...formFieldValues,
                                [res.data.responseData.serverValue]: null,
                            })
                        }
                    })
                    .catch((error) => {
                        HandleServerError(error)
                    });
            } else {
                setLoader(false)
            }
        });
        return derivedQueList;
    }

    const fetchDerivedQuestionsDynamic = (derivedList, fetchedQuesIds, derivedQuesNew, parentId) => {
        const derivedQueList = [];
        const values = formRef.current.getFieldsValue();
        let fetchedList = [];
        let fetchedQueList = [];
        derivedList?.map((item, idx) => {
            if (fetchedQuesIds && !fetchedQuesIds.includes(item)) {
                api.get(`/spinozaService/api/v1/osMeeting/question/${meeting.cityId}/${item}`, {}, { Authorization: `Bearer ${userDetails.token}` })
                    .then((res) => {
                        setLoader(false)
                        if (item === '6364627a83d40f070eab79e0' && formId === '6363a5b183d40f070eab79d3' && shouldLocationBeVisible()) {
                            fetchedList = [...fetchedList, item];
                            fetchedQueList = [...fetchedQueList, res.data.responseData];
                            if (fetchedQuesIds.includes(derivedList[idx - 1]) || (derivedList.length === 1 && !fetchedQuesIds.includes(derivedList[0]))) {
                                setFetchedQuesIds([...fetchedQuesIds, item]);
                                setDerivedQues(sortByKey([...derivedQuesNew, res.data.responseData], 'priority'));
                            } else {
                                setFetchedQuesIds([...fetchedQuesIds, ...fetchedList]);
                                setDerivedQues(sortByKey([...derivedQuesNew, ...fetchedQueList], 'priority'));
                            }
                            derivedQueList.push(res.data.responseData);
                            formRef.current.setFieldsValue({
                                ...formRef.current.getFieldsValue(),
                                ...values,
                                ...formFieldValues,
                                [res.data.responseData.serverValue]: null,
                            })
                        } else if (item === '6364627a83d40f070eab79e0' && !shouldLocationBeVisible()) {
                            return;
                        } else {
                            console.log('derivedList',derivedList) //['6502b4abc565eba43ec2aa14']
                            console.log('fetchedQuesIds',fetchedQuesIds) //[]
                            console.log('derivedQuesNew',derivedQuesNew) //all without derived
                            fetchedList = [...fetchedList, item]; //[14]
                            fetchedQueList = [...fetchedQueList, res.data.responseData]; //[new question]
                            if (fetchedQuesIds.includes(derivedList[idx - 1])) {
                                setFetchedQuesIds([...fetchedQuesIds, item]);
                                setDerivedQues(sortByKey([...derivedQuesNew, res.data.responseData], 'priority'));
                            } else {
                                setFetchedQuesIds([...fetchedQuesIds, ...fetchedList]);
                                setDerivedQues(sortByKey([...derivedQuesNew, ...fetchedQueList], 'priority'));
                            }
                            // derivedQueList.push(res.data.responseData);
                            formRef.current.setFieldsValue({
                                ...formRef.current.getFieldsValue(),
                                ...values,
                                ...formFieldValues,
                                [res.data.responseData.serverValue]: null,
                            })
                        }
                        let temp = derivedQuesIdsDynamic
                        if(temp[parentId]){
                            let filterData = temp[parentId].filter(t=>t==res.data.responseData.id)
                            if(filterData.length) return
                            temp[parentId].push(res.data.responseData.id);
                        } else {
                            temp[parentId] = [res.data.responseData.id]
                        }
                        setTimeout(()=>{
                            setDerivedQuesIdsDynamic(temp)
                        },0)
                    })
                    .catch((error) => {
                        HandleServerError(error)
                    });
            } else {
                setLoader(false)
            }
        });
        return derivedQueList;
    }

    const getTextDefaultValue = (val) => {
        switch (val) {
            case true: return 'Qualified';
            case false: return 'Unqualified';
            default: return val;
        }
    }

    const getDerivedQuestions = (qk,value, fetchedQuesIds=[], derivedQues=[]) => {
        if(qk?.allowMultipleCondition){
            return fetchDerivedQuestionsDynamic((qk?.subQuestionMap.filter(t=>{
                return t.condition==value
            })?.[0]?.questionIds), fetchedQuesIds, derivedQues, qk.id)    
        } else {
            return fetchDerivedQuestions(qk.subQuestionId)
        }
    }

    useEffect(() => {
        if (formRef && formRef.current) {
            formRef.current.setFieldsValue({
                ...formDetails,
                ...formFieldValues,
            });
        }
    }, [formFieldValues]);

    useEffect(() => {
        if (formFieldValues.osMeetingSubStatus !== null) {
            formRef.current.setFieldsValue({
                ...formFieldValues,
                osMeetingSubStatus: null
            });
            formRef.current.setFieldsValue({
                ...formRef.current.getFieldsValue(),
                ...formFieldValues,
                osMeetingSubStatus: null,
            })
            setFormFieldValues({
                ...formFieldValues,
                osMeetingSubStatus: null
            });
        }
    }, [formFieldValues.osMeetingStatus]);


    useEffect(() => {
        if (formRef.current) {
            let values = {
                ...formRef.current.getFieldsValue(),
            };
            let keys = [];
            derivedQues.length !== 0 && derivedQues.map((item) => {
                values = {
                    ...values,
                    [item.serverValue]: formDetails[item.serverValue] ? formDetails[item.serverValue] : null,
                };
                keys.push(item.serverValue)
            });
            formRef.current.setFieldsValue({
                ...formRef.current.getFieldsValue(),
                ...values,
            });
        }
    }, [derivedQues, formDetails]);

    useEffect(() => {
        if (formFieldValues.event) {
            setNextEvent(formFieldValues.event);
        }
    }, [formFieldValues.event]);

    const checkConditionForDerived = (qk, val) => {
        if (qk.subQuestionId && qk.subQuestionId.length !== 0) {
            if (typeof qk.subQuesConditionValue === 'string') {
                if (qk.subQuesConditionValue === 'true' || qk.subQuesConditionValue === 'false') {
                    return val === JSON.parse(qk.subQuesConditionValue);
                } else if (qk.subQuesConditionValue.includes(',')) {
                    let data = qk.subQuesConditionValue.split(', ');
                    return data.includes(val);
                } else {
                    return val === qk.subQuesConditionValue;
                }
            } else if (qk.subQuesConditionValue === null) {
                return true;
            } else {
                return val === JSON.parse(qk.subQuesConditionValue);
            }
        } else return false;
    }

    const renderFormItem = (qk) => {
        if (qk.inputType === 'UPLOAD_IMAGE' || qk.inputType === 'UPLOAD_VIDEO' || qk.inputType === 'UPLOAD_DOC') {
            return
        }
        // let maxDate = new Date();
        // maxDate = new Date(maxDate.setFullYear(maxDate.getFullYear() - 18));
        const { getFieldsValue = () => { }, setFieldsValue = () => { }, getFieldValue = () => { } } = formRef.current;
        const dateValueProps = qk.inputType === 'DOB' ? {
            defaultValue: dayjs((moment(
                formFieldValues[qk.serverValue]
                    ? formFieldValues[qk.serverValue]
                    : getInitialValue(qk.serverValue, qk))
                ? moment(getInitialValue(qk.serverValue, qk)
                ).format('DD-MM-YYYY')
                : ''
            ), 'DD-MM-YYYY')
        } : formFieldValues[qk.serverValue] && (moment(formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)) ? moment(formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)).format('DD-MM-YYYY') : '') ? {
            defaultValue: dayjs((moment(
                formFieldValues[qk.serverValue]
                    ? formFieldValues[qk.serverValue]
                    : getInitialValue(qk.serverValue, qk))
                ? moment(getInitialValue(qk.serverValue, qk)
                ).format('DD-MM-YYYY')
                : ''
            ), 'DD-MM-YYYY')
        } : qk.inputType === 'DATE_TIME' && ((formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)) ? moment(formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)) : '') ? {
            defaultValue: dayjs((moment(formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)) ? moment(formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)).format('DD-MM-YYYY hh:mm A') : ''), 'DD-MM-YYYY hh:mm A')
        } : {};
        let tempTime = formFieldValues[qk.serverValue];
        const timeValueProps = tempTime ? {
            defaultValue: dayjs(moment(tempTime).format('DD-MM-YYYY hh:mm A'), 'DD-MM-YYYY hh:mm A')
        } : {};
        return (
            <div>
                <Form.Item
                    key={qk.id}
                    label={<div>
                        <div>{qk.description}</div>
                        {qk?.questionInformation ? <div style={{color:qk?.informationColour, fontSize:"14px", fontWeight:300, fontStyle:"italic"}}>{qk.questionInformation}</div> : <></>}
                    </div>}
                    name={qk?.serverValue}
                    rules={[{ required: qk.isMandatory, message: "Required" }]}
                    initialValue={getFieldsValue() ? getFieldsValue() : getInitialValue(qk.serverValue, qk)}
                >
                    {(qk.inputType === 'TEXT') && (
                        <div>
                            <Input
                                style={{ border: '0px solid transparent' }}
                                onChange={(e) => {
                                    setFieldsValue({
                                        ...formRef.current.getFieldsValue(),
                                        [qk.serverValue]: e.target.value,
                                    });
                                    setFormFieldValues({
                                        ...formFieldValues,
                                        [qk.serverValue]: e.target.value,
                                    });
                                    if (e.target.value === JSON.parse(qk.subQuesConditionValue) && (qk.subQuestionId && qk.subQuestionId.length !== 0)) {
                                        setLoader(true)
                                        getDerivedQuestions(qk,e.target.value)
                                    } else if (qk.subQuestionId && qk.subQuestionId.length !== 0 && (e.target.value !== JSON.parse(qk.subQuesConditionValue))) {
                                        removeDerivedQue(qk);
                                    }
                                }}
                                placeholder={'Enter text value...'}
                                disabled={!qk.isEditable || disableForm}
                                value={formFieldValues?.[qk?.serverValue]}
                                defaultValue={getTextDefaultValue(formFieldValues?.[qk?.serverValue] ? formFieldValues?.[qk?.serverValue] : getInitialValue(qk?.serverValue, qk))}
                            />
                        </div>
                    )}
                    {(qk.inputType === 'INTEGER') && (
                        <div>
                            <InputNumber
                                style={{ width: '100%' }}
                                defaultValue={formFieldValues?.[qk?.serverValue] ? formFieldValues?.[qk?.serverValue] : getInitialValue(qk?.serverValue, qk)}
                                placeholder={'Enter numeric value...'}
                                onChange={(value) => {
                                    setFieldsValue({
                                        ...formRef.current.getFieldsValue(),
                                        [qk.serverValue]: value,
                                    });
                                    setFormFieldValues({
                                        ...formFieldValues,
                                        [qk.serverValue]: value,
                                    });
                                    if (value === JSON.parse(qk.subQuesConditionValue) && (qk.subQuestionId && qk.subQuestionId.length !== 0)) {
                                        setLoader(true)
                                        getDerivedQuestions(qk,value)
                                    } else if (qk.subQuestionId && qk.subQuestionId.length !== 0 && (value !== JSON.parse(qk.subQuesConditionValue))) {
                                        removeDerivedQue(qk);
                                    }
                                }}
                                disabled={!qk.isEditable || disableForm}
                                min={qk.minValue || 0}
                                max={qk.maxValue}
                            />
                        </div>
                    )}
                    {(qk.inputType === 'DECIMAL') && (
                        <div>
                            <InputNumber
                                style={{ width: '100%' }}
                                defaultValue={formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)}
                                placeholder={'Enter numeric value...'}
                                step={0.1}
                                onChange={(value) => {
                                    setFieldsValue({
                                        ...formRef.current.getFieldsValue(),
                                        [qk.serverValue]: parseFloat(value).toFixed(qk.precision || 2),
                                    });
                                    setFormFieldValues({
                                        ...formFieldValues,
                                        [qk.serverValue]: parseFloat(value).toFixed(qk.precision || 2),
                                    });
                                    if (value === JSON.parse(qk.subQuesConditionValue) && (qk.subQuestionId && qk.subQuestionId.length !== 0)) {
                                        setLoader(true)
                                        getDerivedQuestions(qk,value)
                                    } else if (qk.subQuestionId && qk.subQuestionId.length !== 0 && (value !== JSON.parse(qk.subQuesConditionValue))) {
                                        removeDerivedQue(qk);
                                    }
                                }}
                                disabled={!qk.isEditable || disableForm}
                                min={qk.minValue || 0}
                                max={qk.maxValue}
                            />
                        </div>
                    )}
                    {(qk.inputType === 'RADIO') && (
                        <div>
                            <Radio.Group disabled={!qk.isEditable || disableForm} onChange={(e) => {
                                setFormFieldValues({
                                    ...formFieldValues,
                                    [qk.serverValue]: e.target.value,
                                });
                                setFieldsValue({
                                    ...formRef.current.getFieldsValue(),
                                    [qk.serverValue]: e.target.value,
                                });
                                if (qk.serverValue === 'event') {
                                    setNextEvent(e.target.value);
                                }
                                if (e.target.value === ((qk.subQuesConditionValue === null && qk.id === '636461a183d40f070eab79de' && LOCATION_QUESTION_TYPES.includes(e.target.value)) ? e.target.value : (qk.subQuesConditionValue === 'true' || qk.subQuesConditionValue === 'false')
                                    ? JSON.parse(qk.subQuesConditionValue)
                                    : qk.subQuesConditionValue ? qk.subQuesConditionValue : JSON.parse(qk.subQuesConditionValue)) && (qk.subQuestionId && qk.subQuestionId.length !== 0)) {
                                    setLoader(true)
                                    getDerivedQuestions(qk,e.target.value)
                                } else if (qk.subQuestionId && qk.subQuestionId.length !== 0 && (e.target.value !== (typeof qk.subQuesConditionValue === 'string' ? qk.subQuesConditionValue : JSON.parse(qk.subQuesConditionValue)))) {
                                    removeDerivedQue(qk);
                                }
                            }} defaultValue={(formFieldValues?.[qk?.serverValue] || (formFieldValues?.[qk?.serverValue] === false)) ? formFieldValues?.[qk?.serverValue] : getInitialValue(qk?.serverValue, qk)}>
                                {(qk.serverValue === 'event' ? actionOptions : qk.options).map((option, ind) => (
                                    <Radio value={option.uniqueId} key={ind}>{option.description}</Radio>
                                ))}
                            </Radio.Group>
                        </div>
                    )}
                    {(qk.inputType === 'RATING') && (
                        <div>
                            <Rate count={qk.maxValue} disabled={!qk.isEditable || disableForm} defaultValue={formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)} />
                        </div>
                    )}
                    {(qk.inputType === 'DOB') && (
                        <div>
                            <DatePicker
                                format={'DD-MM-YYYY'}
                                onChange={(val) => {
                                    setFieldsValue({
                                        ...formRef.current.getFieldsValue(),
                                        [qk.serverValue]: val,
                                    });
                                    setFormFieldValues({
                                        ...formFieldValues,
                                        [qk.serverValue]: val,
                                    });
                                    if (val === JSON.parse(qk.subQuesConditionValue) && (qk.subQuestionId && qk.subQuestionId.length !== 0)) {
                                        setLoader(true)
                                        getDerivedQuestions(qk,val)
                                    } else if (qk.subQuestionId && qk.subQuestionId.length !== 0 && (val !== JSON.parse(qk.subQuesConditionValue))) {
                                        removeDerivedQue(qk);
                                    }
                                }}
                                // disabledDate={d => !d || d.isAfter(maxDate)}
                                // {...dateValueProps}
                                placeholder='DD-MM-YYYY'
                                disabled={!qk.isEditable || disableForm}
                                defaultValue={formFieldValues?.[qk?.serverValue] ? moment(formFieldValues?.[qk?.serverValue]) : moment(getInitialValue(qk?.serverValue, qk))}
                            />
                        </div>
                    )}
                    {(qk.inputType === 'DATE') && (
                        <div>
                            <DatePicker
                                format={'DD-MM-YYYY'}
                                onChange={(val) => {
                                    setFieldsValue({
                                        ...formRef.current.getFieldsValue(),
                                        [qk.serverValue]: val,
                                    });
                                    setFormFieldValues({
                                        ...formFieldValues,
                                        [qk.serverValue]: val,
                                    });
                                    if (val === JSON.parse(qk.subQuesConditionValue) && (qk.subQuestionId && qk.subQuestionId.length !== 0)) {
                                        setLoader(true)
                                        getDerivedQuestions(qk,val)
                                    } else if (qk.subQuestionId && qk.subQuestionId.length !== 0 && (val !== JSON.parse(qk.subQuesConditionValue))) {
                                        removeDerivedQue(qk);
                                    }
                                }}
                                // {...dateValueProps}
                                placeholder='DD-MM-YYYY'
                                disabled={!qk.isEditable || disableForm}
                                defaultValue={formFieldValues?.[qk?.serverValue] ? moment(formFieldValues?.[qk?.serverValue]) : null}
                            />
                        </div>
                    )}
                    {(qk.inputType === 'DATE_TIME') && (
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <DatePicker
                                style={{ maxWidth: '150px' }}
                                onChange={(val) => {
                                    // setFieldsValue({
                                    //     ...formRef.current.getFieldsValue(),
                                    //     [qk.serverValue]: val,
                                    // });
                                    // setFormFieldValues({
                                    //     ...formFieldValues,
                                    //     [qk.serverValue]: val,
                                    // });
                                    tempTime = new Date(val);
                                    if (val === JSON.parse(qk.subQuesConditionValue) && (qk.subQuestionId && qk.subQuestionId.length !== 0)) {
                                        setLoader(true)
                                        getDerivedQuestions(qk,val)
                                    } else if (qk.subQuestionId && qk.subQuestionId.length !== 0 && (val !== JSON.parse(qk.subQuesConditionValue))) {
                                        removeDerivedQue(qk);
                                    }
                                }}
                                format={"DD-MM-YYYY"}
                                placeholder='DD-MM-YYYY'
                                // {...dateValueProps}
                                disabled={!qk.isEditable || disableForm}
                            />
                            <TimePicker
                                style={{ maxWidth: '150px' }}
                                onChange={(val) => {
                                    let time = new Date(val).setDate(new Date(tempTime).getDate())
                                    tempTime = new Date(time).toISOString()
                                    setFieldsValue({
                                        ...formRef.current.getFieldsValue(),
                                        [qk.serverValue]: new Date(time).toISOString(),
                                    });
                                    setFormFieldValues({
                                        ...formFieldValues,
                                        [qk.serverValue]: new Date(time).toISOString(),
                                    });
                                    if (new Date(time) === JSON.parse(qk.subQuesConditionValue) && (qk.subQuestionId && qk.subQuestionId.length !== 0)) {
                                        setLoader(true)
                                        getDerivedQuestions(qk,val)
                                    } else if (qk.subQuestionId && qk.subQuestionId.length !== 0 && (new Date(time).toISOString() !== JSON.parse(qk.subQuesConditionValue))) {
                                        removeDerivedQue(qk);
                                    }
                                }}
                                format={"hh:mm A"}
                                placeholder='HH:MM A'
                                // {...timeValueProps}
                                disabled={!qk.isEditable || disableForm}
                            />
                        </div>
                    )}
                    {(qk.inputType === 'SELECT') && (
                        <div>
                            <Select
                                allowClear
                                style={{
                                    color: 'black',
                                    border: '0px solid transparent'
                                }}
                                disabled={!qk.isEditable || disableForm}
                                showSearch
                                onChange={(val) => {
                                    // if (qk.serverValue === 'osMeetingStatus') {
                                    //     removeDerivedQue(qk);
                                    // }
                                    setFieldsValue({
                                        ...formRef.current.getFieldsValue(),
                                        [qk.serverValue]: val,
                                    });
                                    setFormFieldValues({
                                        ...formFieldValues,
                                        [qk.serverValue]: val,
                                    });
                                    if (qk.serverValue === 'osMeetingStatus') {
                                        let statusOptions = qk.options;
                                        let targetOptions = statusOptions.filter((i, v) => i.uniqueId === val);
                                        setSubOptiions(targetOptions[0].subStatus);
                                    };
                                    console.log(qk,val)
                                    if (!checkConditionForDerived(qk, val)) {
                                        if(qk.allowMultipleCondition){
                                            const {derivedQueIds, queList} = removeDerivedDynamic(qk?.subQuestionMap.filter(t=>{
                                                return t.condition==val
                                            })?.[0]?.questionIds,qk.id);
                                            setTimeout(()=>{
                                                getDerivedQuestions(qk,val, derivedQueIds, queList)
                                            },100)
                                        } else {
                                            removeDerivedQue(qk);
                                        }
                                    } else if (checkConditionForDerived(qk, val)) {
                                        setLoader(true)
                                        getDerivedQuestions(qk,val)
                                    }
                                }}
                                defaultValue={formFieldValues?.[qk?.serverValue] ? formFieldValues?.[qk?.serverValue] : getInitialValue(qk?.serverValue, qk)}
                                placeholder="Select here"
                            >
                                {(qk.serverValue === 'osMeetingSubStatus' ? subOptions : qk.options).map((option) => (
                                    <Select.Option key={option.uniqueId} value={option.uniqueId}>
                                        {option.description}
                                    </Select.Option>
                                ))
                                }
                            </Select>
                        </div>
                    )}
                    {(qk.inputType === 'MULTI_SELECT') && (
                        <div>
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    color: 'black',
                                }}
                                onChange={(val) => {
                                    setFieldsValue({
                                        ...formRef.current.getFieldsValue(),
                                        [qk.serverValue]: val,
                                    });
                                    setFormFieldValues({
                                        ...formFieldValues,
                                        [qk.serverValue]: val,
                                    });
                                    if (qk.serverValue === 'osMeetingOutcome') {
                                        setActionOptions(getActionOptions(val));
                                    };
                                    if ((val === (typeof qk.subQuesConditionValue === 'string' ? qk.subQuesConditionValue : JSON.parse(qk.subQuesConditionValue)) && (qk.subQuestionId && qk.subQuestionId.length !== 0)) || (qk.subQuesConditionValue === null && (qk.subQuestionId && qk.subQuestionId.length !== 0)) || val.includes(qk.subQuesConditionValue)) {
                                        setLoader(true)
                                        getDerivedQuestions(qk,val)
                                    } else if (qk.subQuestionId && qk.subQuestionId.length !== 0 && (val !== qk.subQuesConditionValue)) {
                                        removeDerivedQue(qk);
                                    }
                                }}
                                disabled={!qk.isEditable || disableForm}
                                showSearch
                                defaultValue={formFieldValues?.[qk?.serverValue] ? formFieldValues?.[qk?.serverValue] : getInitialValue(qk?.serverValue, qk)}
                                placeholder="Select here"
                            >
                                {qk.serverValue === 'packageSelected' ?
                                    (
                                        cityPackages.map((option) => (
                                            <Select.Option key={option.packageUniqueId} value={option.packageUniqueId}>
                                                {option.packageDescription}
                                            </Select.Option>
                                        ))
                                    )
                                    : (
                                        qk.options.map((option) => (
                                            <Select.Option key={option.uniqueId} value={option.uniqueId}>
                                                {option.description}
                                            </Select.Option>
                                        ))
                                    )
                                }
                            </Select>
                        </div>
                    )}
                    {/* {(qk.inputType === 'UPLOAD_IMAGE') && (
                        <div>
                            <Upload
                                maxCount={5}
                                key={qk.serverValue}
                                disabled={disableForm}
                                listType="picture-card"
                                onChange={(list) => handleChange(list, qk.serverValue)}
                                customRequest={(data) => uploadFiles(data, qk.serverValue)}
                                fileList={formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)}
                                accept=".jpg, .png, .jpeg"
                            >
                                {(formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk) ? getInitialValue(qk.serverValue, qk) : []).length >= 5 ? null : uploadButton}
                            </Upload>
                        </div>
                    )}
                    {(qk.inputType === 'UPLOAD_VIDEO') && (
                        <div>
                            <Upload
                                maxCount={5}
                                disabled={disableForm}
                                listType="picture-card"
                                onChange={(list) => handleChange(list, qk.serverValue)}
                                customRequest={(data) => uploadFiles(data, qk.serverValue)}
                                fileList={formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)}
                                accept=".mp4, .mov, .avi, .wmv"
                            >
                                {(formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk) ? getInitialValue(qk.serverValue, qk) : []).length >= 5 ? null : uploadButton}
                            </Upload>
                        </div>
                    )}
                    {(qk.inputType === 'UPLOAD_DOC') && (
                        <div>
                            <Upload
                                maxCount={5}
                                disabled={disableForm}
                                listType="picture-card"
                                onChange={(list) => handleChange(list, qk.serverValue)}
                                customRequest={(data) => uploadFiles(data, qk.serverValue)}
                                fileList={formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk)}
                                accept=".pdf, .txt"
                            >
                                {(formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : getInitialValue(qk.serverValue, qk) ? getInitialValue(qk.serverValue, qk) : []).length >= 5 ? null : uploadButton}
                            </Upload>
                        </div>
                    )} */}
                    {(qk.inputType === 'LOCATION' && shouldLocationBeVisible()) && (
                        <div key={qk.serverValue}>
                            <LocationPickerModal
                                uniqueKey={qk.serverValue}
                                isModalVisible={isLocationPickerModalVisible && locationPickerField === qk.serverValue}
                                hideModal={() => {
                                    setLocationPickerField(null);
                                    setIsLocationPickerModalVisible(false);
                                }}
                                defaultLocation={formFieldValues && formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : (qk.serverValue === 'meetingLocation') ? getMeetingLocation() : {
                                    lat: -1,
                                    lng: -1,
                                    address: "",
                                }}
                                pushLocation={(location) => {
                                    setFieldsValue({
                                        ...formRef.current.getFieldsValue(),
                                        [qk.serverValue]: location,
                                    });
                                    setFormFieldValues({
                                        ...formFieldValues,
                                        [qk.serverValue]: location,
                                    });
                                    setLocationPickerField(null);
                                }}
                            />
                            <Input
                                key={qk.serverValue}
                                readOnly
                                onDoubleClick={(e) => {
                                    setIsLocationPickerModalVisible(true);
                                }}
                                onKeyDown={(e) => {
                                    setIsLocationPickerModalVisible(true);
                                }}
                                value={formFieldValues && formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue].address : ''}
                                suffix={
                                    <FullscreenOutlined
                                        style={{ cursor: "pointer" }}
                                        width="20px"
                                        height="20px"
                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Google_Maps_icon_%282020%29.svg/1200px-Google_Maps_icon_%282020%29.svg.png"
                                        onClick={() => {
                                            setLocationPickerField(qk.serverValue)
                                            setIsLocationPickerModalVisible(true);
                                        }}
                                    />
                                }
                            />
                        </div>
                    )}
                    {(qk.inputType === 'LOCATION_INPUT') && (
                        <div key={qk.serverValue}>
                            <LocationPickerModal
                                setPincodeValue={
                                    (pincode)=>{
                                        if(qk.serverValue==="customerCurrentLocation"){
                                            setFieldsValue({
                                                ...formRef.current.getFieldsValue(),
                                                ['customerPincode']: pincode,
                                            });
                                            setFormFieldValues({
                                                ...formFieldValues,
                                                ['customerPincode']: pincode,
                                            });
                                        }else{
                                            setFieldsValue({
                                                ...formRef.current.getFieldsValue(),
                                                ['pincode']: pincode,
                                            });
                                            setFormFieldValues({
                                                ...formFieldValues,
                                                ['pincode']: pincode,
                                            });
                                        }
                                     
                                    }
                                }
                                uniqueKey={qk.serverValue}
                                isModalVisible={isLocationPickerModalVisible && locationPickerField === qk.serverValue}
                                hideModal={() => {
                                    setLocationPickerField(null);
                                    setIsLocationPickerModalVisible(false);
                                }}
                                defaultLocation={formFieldValues && formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue] : {
                                    lat: -1,
                                    lng: -1,
                                    address: "",
                                }}
                                pushLocation={(location) => {
                                    setFieldsValue({
                                        ...formRef.current.getFieldsValue(),
                                        [qk.serverValue]: location,
                                    });
                                    setFormFieldValues({
                                        ...formFieldValues,
                                        [qk.serverValue]: location,
                                    });
                                    setLocationPickerField(null);
                                }}
                            />
                            <Input
                                key={qk.serverValue}
                                readOnly
                                onDoubleClick={(e) => {
                                    setIsLocationPickerModalVisible(true);
                                }}
                                onKeyDown={(e) => {
                                    setIsLocationPickerModalVisible(true);
                                }}
                                disabled={!qk.isEditable || disableForm}
                                value={formFieldValues && formFieldValues[qk.serverValue] ? formFieldValues[qk.serverValue].address : ''}
                                suffix={
                                    <FullscreenOutlined
                                        style={{ cursor: "pointer" }}
                                        width="20px"
                                        height="20px"
                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Google_Maps_icon_%282020%29.svg/1200px-Google_Maps_icon_%282020%29.svg.png"
                                        onClick={() => {
                                            if(!qk.isEditable || disableForm) return
                                            setLocationPickerField(qk.serverValue)
                                            setIsLocationPickerModalVisible(true);
                                        }}
                                    />
                                }
                            />
                        </div>
                    )}
                </Form.Item>
            </div>
        )
    }
    return (
        <Spin spinning={loader}>
            <Form
                ref={formRef}
                style={{
                    padding: '20px 0px 10px 0px'
                }}
            >

                {derivedQues.length !== 0 && derivedQues.map((qk, ind) => {
                    let fieldValue = getInitialValue(qk.serverValue, qk);
                    return (
                        <div key={ind}>
                            {renderFormItem(qk)}
                        </div>
                    )
                })}
                <Form.Item {...tailWrapperLayout} style={{ textAlign: 'center' }}>
                    {initialForm && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '-30px' }}>
                            <Button loading={submitLoading} onClick={() => {
                                if (disableForm) {
                                    moveNext();
                                    return;
                                }
                                formRef.current.setFieldsValue({
                                    ...formFieldValues,
                                })
                                let payload = formRef.current.getFieldsValue();
                                let invalid = false;
                                Object.keys(payload).map((item) => {
                                    if (formFieldValues[item] === null || formFieldValues[item] === undefined) {
                                        invalid = true;
                                    }
                                });
                                if (invalid) {
                                    message.error('Fill all required fields.');
                                } else {
                                    setSubmitLoading(true)
                                    onSubmit(payload);
                                }
                            }} type="submit" htmlType="submit" style={{ fontSize: '11px', width: '100%', color: '#f15a29', border: '1px solid #f15a29', backgroundColor: 'white' }}>{disableForm ? 'CONTINUE' : 'SAVE & CONTINUE'}</Button>
                        </div>
                    )}
                    {!initialForm && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '-30px' }}>
                            <Button
                                type="primary"
                                htmlType="button"
                                style={{ width: '48%', color: '#e7b366', border: '1px solid #e7b366', backgroundColor: 'white' }}
                                onClick={onBack}
                            >PREVIOUS</Button>
                            <Button disabled={disableSubmitButton} loading={submitLoading} onClick={() => {
                                if(sessionStorage.getItem('isSubmitted')=='true')
                                return
                                if (disableForm) {
                                    moveNext();
                                    return;
                                }
                                derivedQues.map((qk) => {
                                    const { setFieldsValue = () => { } } = formRef.current;
                                    let maxDate = new Date();
                                    maxDate = new Date(maxDate.setFullYear(maxDate.getFullYear() - 18));
                                    if (qk.inputType === 'DOB') {
                                        setFormFieldValues({
                                            ...formFieldValues,
                                            [qk.serverValue]: dayjs((moment(
                                                formFieldValues[qk.serverValue]
                                                    ? formFieldValues[qk.serverValue]
                                                    : getInitialValue(qk.serverValue, qk))
                                                ? moment(formFieldValues[qk.serverValue]
                                                    ? formFieldValues[qk.serverValue]
                                                    : getInitialValue(qk.serverValue, qk)
                                                ).format('DD-MM-YYYY')
                                                : moment(maxDate).format('DD-MM-YYYY')
                                            ), 'DD-MM-YYYY'),
                                        });
                                        setFieldsValue({
                                            ...formRef.current.getFieldsValue(),
                                            [qk.serverValue]: dayjs((moment(
                                                formFieldValues[qk.serverValue]
                                                    ? formFieldValues[qk.serverValue]
                                                    : getInitialValue(qk.serverValue, qk))
                                                ? moment(formFieldValues[qk.serverValue]
                                                    ? formFieldValues[qk.serverValue]
                                                    : getInitialValue(qk.serverValue, qk)
                                                ).format('DD-MM-YYYY')
                                                : moment(maxDate).format('DD-MM-YYYY')
                                            ), 'DD-MM-YYYY'),
                                        });
                                    }
                                })
                                formRef.current.setFieldsValue({
                                    ...formFieldValues,
                                });
                                let payload = formRef.current.getFieldsValue();
                                let invalid = false;
                                let locationInvalid = false;
                                Object.keys(payload).map((item) => {
                                    let qk = derivedQues.filter((i, v) => i.serverValue === item)[0];
                                    if (((payload[item] === null) || payload[item] === undefined) && qk.isMandatory) {
                                        invalid = true;
                                    }
                                    if (payload.meetingLocation && payload.meetingLocation.latitude && (payload.meetingLocation.latitude === -1 || payload.meetingLocation.longitude === -1 || payload.meetingLocation.address === "")) {
                                        locationInvalid = true;
                                    }
                                });
                                if (invalid || locationInvalid) {
                                    if (invalid) {
                                        message.error('Fill all required fields.');
                                    } else if (locationInvalid) {
                                        message.error('Please select valid location.');
                                    }
                                } else {
                                    // setSubmitLoading(true)
                                    setDisableSubmitButton(true)
                                    setTimeout(()=>{
                                        setDisableSubmitButton(false)
                                    },5000)
                                    sessionStorage.setItem('isSubmitted','true');
                                    onSubmit(payload);
                                }
                            }} type="submit" htmlType="submit" style={{ fontSize: '11px', width: '48%', color: !disableSubmitButton && '#f15a29', border: !disableSubmitButton && '1px solid #f15a29', backgroundColor: 'white' }}>{disableForm ? 'CONTINUE' : 'SAVE & CONTINUE'}</Button>
                        </div>
                    )}
                </Form.Item>
            </Form>
        </Spin>
    )
};

export default DynamicForm;