import { Card } from 'antd';
import React, { useState } from 'react';
import Icon from '@ant-design/icons/lib/components/Icon';
import FloorUnit from '../../cells/FloorUnit';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';

const QuotationFloor = ({
    floor,
    baseUnit,
    cityPackages,
    mutateFloorsData,
    id,
    onSuccess,
    onError,
}) => {
    const [floorData, setFloorData] = useState(floor);
  
    const mutateProperty = (prop, value) => {
      setFloorData({
        ...floorData,
        [prop]: value,
      });
  
      mutateFloorsData(id, {
        ...floorData,
        [prop]: value,
      });
    };
  
    const addUnit = () => {
      let tempUnits = floorData.floorUnits.slice();
  
      tempUnits.push(baseUnit);
      onSuccess('Unit added');
      mutateProperty('floorUnits', tempUnits);
    };
  
    const removeUnit = (id) => {
      let tempUnits = floorData.floorUnits.filter((item, i) => i !== id);
  
      onSuccess('Unit removed');
      mutateProperty('floorUnits', tempUnits);
    };
  
    const mutateUnit = (id, unit) => {
      let tempUnits = floorData.floorUnits.slice();
  
      tempUnits[id] = unit;
      tempUnits[id].floorUnitDescription =
        tempUnits[id].noOfFloorUnits +
        ' units of ' +
        tempUnits[id].typeOfFloorUnit;
      mutateProperty('floorUnits', tempUnits);
    };
  
  
    return (
        <>
            <Card
                header
                onClick={() => mutateProperty('collapsed', !floor.collapsed)}
                button
                bordered
            >
                {/* <label style={{...styles.cardHeader, color: PRIMARY_COLOR}}> */}
                <label style={{ fontWeight: '600' }}>
                    {floorData.floorNumber} Floor
                </label>
                {floor.collapsed ? (
                  <CaretDownFilled style={{ marginLeft: '10px' }} />
                ): (
                  <CaretUpFilled style={{ marginLeft: '10px' }} />
                )}
            </Card>
            {!floor.collapsed && (
                <>
                  {floorData.floorUnits.map((unit, id) => (
                      <FloorUnit
                          floorUnit={unit}
                          key={id}
                          id={id}
                          cityPackages={cityPackages}
                          removeUnit={() => removeUnit(id)}
                          mutateUnit={mutateUnit}
                      />
                  ))}

                  <button style={{ backgroundColor: '#58c9ed', color: 'white', width: '100%', padding: '10px', textAlign: 'center', border: '0px solid transparent', borderRadius: '5px' }} onClick={() => addUnit()} info>
                    ADD FLOOR UNIT
                  </button>
                </>
            )}
        </>
    );
};

export default QuotationFloor;