import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import query from 'query-string';
import CustomCard from '../../components/atoms/CustomCard';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../utils/apiClient';
import DynamicForm from './form';
import FormHeader from '../../components/atoms/FormHeader';
import { Alert, message, Spin } from 'antd';
import './styles.scss';
import QuotationForm from './quotationForm';
import CustomPopup from '../../components/atoms/CustomPopup';
import successIcon from '../../assets/images/success.png';
import failureIcon from '../../assets/images/failure.png';
import { HandleServerError } from '../../utils/serverErrorHandler';
import { isArray, isEmpty } from 'lodash';
import {prefilledFormValidation} from '../../constants/enums'
const QuestionForm = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [leadQualified, setLeadQualified] = useState(false);
  const [leadQualificationText,setLeadQualificationText] = useState('')
  const [showQuotationForm, setShowQuotationForm] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [meeting, setMeetingDetails] = useState({});
  const [dynamicFormDetails, setDynamicFormDetails] = useState({});
  const [formDetails, setFormDetails] = useState(location.state && location.state.topicDetails ? location.state.topicDetails : {});
  const [crnDetails, setCrnDetails] = useState({});
  const [questions, setQuestions] = useState([]);
  const [params, setParams] = useState({});
  const [routeState, setRouteState] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false)
  useEffect(() => {
    if (!params && !routeState) {
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    setParams(query.parse(location.search));
  }, [location.search]);
  useEffect(() => {
    setRouteState(location.state);
  }, [location.state]);

  useEffect(() => {
    if (routeState?.topicDetails) {
      setFormDetails(routeState?.topicDetails);
    }
  }, [routeState?.topicDetails]);
  const getMeetingDetails = () => {
    let url = `/spinozaService/api/spinoza/v1/meetings/${params.meetingId}`
    if(params.crnId){
      url += `/${params.crnId}`
    }
    api
      .get(url, {}, {
        Authorization: `Bearer ${params.token}`
      })
      .then((res) => {
        setMeetingDetails(res.data.responseData);
      })
      .catch((error) => {
        HandleServerError(error)
      });
  };

  const getFormQuestions = () => {
    if (routeState && routeState.quotationForm) {
      setShowQuotationForm(true);
    } else {
      setLoading(true);
      api
        .post(
          '/spinozaService/api/v1/osMeeting/topic',
          {},
          {
            topicId: params.formId,
            crnId: params.crnId,
          }, {
          Authorization: `Bearer ${params.token}`
        }
        )
        .then((res) => {
          setQuestions(res.data.responseData.osMeetingQuestionList);
          setCrnDetails(res.data.responseData.crnDetail);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          HandleServerError(e)
        });
    }
  };

  const moveNext = () => {
    setLoading(true);
    if (params.formId === '6363a5b183d40f070eab79d3') {
      navigate(`/?meetingId=${meeting.meetingId}&token=${params.token}&latitude=${params.latitude}&longitude=${params.longitude}`)
    } else {
      setQuestions([]);
      setLoading(true);
      let nextOptionForm = routeState.topicsList.filter((i, v) => i.id === formDetails.nextFormId) && routeState.topicsList.filter((i, v) => i.id === formDetails.nextFormId).length !== 0 ? routeState.topicsList.filter((i, v) => i.id === formDetails.nextFormId)[0] : {};
      navigate(`/form${(location.search).replace(`&formId=${params.formId}`, `&formId=${formDetails.nextFormId}`).replace(`&crnId=${params.crnId}`, `&crnId=${params.crnId}`)}`, {
        state: {
          topicDetails: nextOptionForm,
          initialForm: false,
          topicsList: routeState.topicsList,
          userDetails: {
            ...routeState.userDetails,
          }
        }
      });
    }
  }

  useEffect(() => {
    if (params.formId && params.crnId) {
      getMeetingDetails();
      getFormQuestions();
    } else if (!params.formId && params.meetingId){
        getMeetingDetails();
    }
  }, [params.formId, params.crnId, params.meetingId]);

  const onSuccess = (text) => {
    message.success(text);
  };

  const onError = (text) => {
    messageApi.open({
      type: 'error',
      content: text,
      icon: () => (<></>),
      className: 'custom-error-message',
      style: {
        marginTop: '90%'
      },
    });
  };

  const onFormSubmit = (payload) => {
    let nextStepPayload = formDetails.id === "6363a5b183d40f070eab79d3" ? {
      srUniqueId: params.crnId ? params.crnId : meeting.crnId,
      assignedTo: routeState.userDetails.employeeUserId,
    } : {};
    console.log("payload", payload)
    let baseUrl = `/spinozaService${formDetails.endpoint.replace('${id}', params.meetingId)}`;
    api.put(baseUrl, {}, {
      ...payload,
      ...nextStepPayload,
      crnId: params.crnId,
      geoTracking: {
        latitude: params.latitude,
        longitude: params.longitude,
      },
    }, {
      Authorization: `Bearer ${params.token}`
    }).then((res) => {
      if (res.status === 200) {
        if (res.data.status === "SUCCESS") {
          getMeetingDetails();
          setSubmitLoading(false)
          message.success('Data saved successfully!');
          if(res.data.responseData.shouldShowPopUp){
            setLeadQualified(res.data.responseData.leadQualified);
            setLeadQualificationText(res.data.responseData.leadQualificationValue)
            setPopupVisible(true);
            return;
          } else if (params.formId === '6363a5b183d40f070eab79d3') {
            navigate(`/?meetingId=${meeting.meetingId}&token=${params.token}&latitude=${params.latitude}&longitude=${params.longitude}`)
          } else {
            moveNext();
          }
        } else {
          setSubmitLoading(false)
          message.error(res.data.errorMsg)
          onError(res.data.errorMsg);
          window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'error',
            message: 'api unauthorized failure'
          }))
        }
      } else {
        onError("Something went wrong");
      }
    })
      .catch((e) => {
        HandleServerError(e)
      })
      .finally((res)=>{
        sessionStorage.removeItem('isSubmitted');
      })
  }
  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(false)
    }
  }, [window && window.ReactNativeWebView]);

  useEffect(() => {
    setDynamicFormDetails(meeting[`${formDetails.serverValue}`] || {});
  }, [meeting, formDetails.serverValue, params.formId])

const formattedText = leadQualificationText.split('\n').map((line, index) => (
  <React.Fragment key={index}>
      {line}
      <br />
  </React.Fragment>
));
  return (
    <>
      <div>
        <FormHeader headerTitle={formDetails.description} headerLeftClick={() => {
          navigate(-1);
        }} />
        <Spin spinning={loading}>
          <CustomCard>
            <>
              {!showQuotationForm && !isEmpty(crnDetails) && (prefilledFormValidation.includes(formDetails.serverValue) ? true : !isEmpty(dynamicFormDetails)) && (dynamicFormDetails) && questions && questions.length !== 0 && (
                <DynamicForm
                  formName={`${formDetails.serverValue}_form`}
                  initialForm={routeState.initialForm}
                  formDetails={{
                    ...dynamicFormDetails,
                    ...crnDetails,
                  }}
                  meeting={meeting}
                  disableForm={meeting.isFormDisabled}
                  onBack={() => {
                    setQuestions([]);
                    setLoading(true);
                    navigate(-1);
                  }}
                  formId={params.formId}
                  moveNext={moveNext}
                  apiToken={params.token}
                  questions={questions}
                  onSubmit={onFormSubmit}
                  onSuccess={onSuccess}
                  onError={onError}
                  userDetails={{
                    token: params.token,
                    currentLocation: {
                      latitude: params.latitude,
                      longitude: params.longitude,
                    }
                  }}
                  submitLoading={submitLoading}
                  setSubmitLoading={setSubmitLoading}
                />
              )}
              {showQuotationForm && (
                <QuotationForm
                  meeting={meeting}
                  userDetails={{
                    token: params.token,
                    ...routeState.userDetails,
                  }}
                  setLoading={setLoading}
                  onSuccess={onSuccess}
                  onError={onError}
                  onBack={() => navigate(-1)}
                />
              )}
            </>
          </CustomCard>
        </Spin>
      </div>
      <CustomPopup
        visible={popupVisible}
        onConfirm={() => {
          setQuestions([]);
          let nextOptionForm = routeState.topicsList.filter((i, v) => i.id === formDetails.nextFormId) && routeState.topicsList.filter((i, v) => i.id === formDetails.nextFormId).length !== 0 ? routeState.topicsList.filter((i, v) => i.id === formDetails.nextFormId)[0] : {};
          navigate(`/form${(location.search).replace(`&formId=${params.formId}`, `&formId=${formDetails.nextFormId}`).replace(`&crnId=${params.crnId}`, `&crnId=${params.crnId}`)}`, {
            state: {
              topicDetails: nextOptionForm,
              initialForm: false,
              topicsList: routeState.topicsList,
              userDetails: {
                ...routeState.userDetails,
              }
            }
          });
          setPopupVisible(false);
        }}
      >
        <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={leadQualified
              ? successIcon
              : failureIcon}
            alt={'head-logo'}
            style={{
              minWidth: leadQualified ? '100px' : '80px',
              height: leadQualified ? '100px' : '80px',
            }}
          />
          <label style={{textAlign:"center"}}>{formattedText}</label>
        </div>
      </CustomPopup>
    </>
  )
};

export default QuestionForm;