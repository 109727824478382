export const RESET = 'RESET';
export const SET_CITY = 'SET_CITY';
export const SET_USER = 'SET_USER';
export const SET_USER_TOKEN = 'SET_USER_TOKEN';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_SNACKBAR_PROPS = 'SET_SNACKBAR_PROPS';
export const SEARCH_ISSUE = 'SEARCH_ISSUE';
export const GET_DESIGN = 'GET_DESIGN';


export const resetStore = user => ({
    type: RESET,
    data: { user }
});
  
export const cityChange = data => ({
    type:SET_CITY,
    data
});

export const setUser = user => ({
    type: SET_USER,
    data: { user }
});

export const setUserDetails = data =>({
    type: SET_USER_DETAILS,
    data,
});
export const setUserToken = token =>({
    type: SET_USER_TOKEN,
    token,
});

export const setSnackBarProps = data => ({
    type: SET_SNACKBAR_PROPS,
    data
});