import axios from 'axios';

import Debug from 'debug';
import { getHostName, isServer } from './server';
import config from '../config';

// eslint-disable-next-line no-underscore-dangle
const debug = Debug('sartre-ui:api');

const GET = 'get';
const POST = 'post';
const DELETE = 'delete';
const PUT = 'put';


export function apiClient(apiLib) {
    const defaultOptions = {
        method: GET,
        url: '',
        params: {},
        data: null,
    };

    return (useCustom) => (apiEndpoint) => ({
        get(url, params = {}, headers = {}) {
            const urlEndpoint = useCustom ? apiEndpoint : apiEndpoint();
            const absoluteUrl = urlEndpoint + url;
            debug('GET request to url: ', absoluteUrl);

            return apiLib({
                ...defaultOptions,
                method: GET,
                url: absoluteUrl,
                params,
                headers: {
                    // any custom headers go here
                    ...headers,
                },
            });
        },
        delete(url, params = {}, headers = {}) {
            const urlEndpoint = useCustom ? apiEndpoint : apiEndpoint();
            const absoluteUrl = urlEndpoint + url;
            debug('DELETE request to url: ', absoluteUrl);

            return apiLib({
                ...defaultOptions,
                method: DELETE,
                url: absoluteUrl,
                params,
                headers: {
                    // any custom headers go here
                    ...headers,
                },
                xsrfCookieName: 'add csrf cookie name here',
                xsrfHeaderName: 'add csrf header name',
            });
        },
        put(url, params = {}, data = {}, headers = {}) {
            const urlEndpoint = useCustom ? apiEndpoint : apiEndpoint();
            const absoluteUrl = urlEndpoint + url;
            
            return apiLib({
                ...defaultOptions,
                method: PUT,
                url: absoluteUrl,
                params,
                data,
                headers: {
                    // any custom headers go here
                    ...headers,
                },
                xsrfCookieName: 'add csrf cookie name here',
                xsrfHeaderName: 'add csrf header name',
            });
        },
        post(url, params = {}, data = {}, headers = {}, apiConfig = {}) {
            const urlEndpoint = useCustom ? apiEndpoint : apiEndpoint();
            const absoluteUrl = urlEndpoint + url;
            debug('POST request to url:', absoluteUrl);

            return apiLib({
                ...defaultOptions,
                method: POST,
                url: absoluteUrl,
                params,
                data,
                headers: {
                    ...headers,
                    // any custom headers go here
                    ...headers,
                },
                xsrfCookieName: 'add csrf cookie name here',
                xsrfHeaderName: 'add csrf header name',
                ...apiConfig,
            });
        },
        multiPart(url, params = {}, data = {}, headers = {}) {
            const urlEndpoint = useCustom ? apiEndpoint : apiEndpoint();
            const absoluteUrl = urlEndpoint + url;
                        debug('Attempting multi-part request to url:', absoluteUrl);

            return apiLib({
                ...defaultOptions,
                method: POST,
                url: absoluteUrl,
                params,
                data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...headers,
                },
                xsrfCookieName: 'add csrf cookie name here',
                xsrfHeaderName: 'add csrf header name',
            });
        },
        blob(url, params = {}, headers = {}) {
            const urlEndpoint = useCustom ? apiEndpoint : apiEndpoint();
            const absoluteUrl = urlEndpoint + url;
                        debug('Attempting blob download of file:', absoluteUrl);

            return apiLib({
                ...defaultOptions,
                method: GET,
                url: absoluteUrl,
                params,
                headers: {
                    // any custom headers go here
                    ...headers,
                },
                responseType: 'arraybuffer', // this is what makes it go in download mode
            });
        },
    });
}

export const customApiCaller = apiClient(axios)(true);

const computeApiEndpoint = () => {
    if (config.USE_CUSTOM_API_ENDPOINT) {
        return config.API_ENDPOINT;
    }
    return getHostName(isServer)();
};

const computeApiEndpointProfessional = () => {
    return config.API_ENDPOINT_PROFESSIONAL;
};

const computeApiEndpointMeucci = () => {
    return config.API_ENDPOINT_MEUCCI;
};

const api = apiClient(axios)(false)(computeApiEndpoint);

export const apiProfessional = apiClient(axios)(false)(computeApiEndpointProfessional);

export const apiMeucci = apiClient(axios)(false)(computeApiEndpointMeucci);

export default api;
