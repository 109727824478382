import React from 'react';
import { Modal } from 'antd';
import './style.scss';

const AlertModal = ({
    alertTitle = '',
    bodyContent = '',
    okText='Okay',
    cancelText='Cancel',
    visible = false,
    canClose = false,
    onSuccess = () => {},
    onCancel = () => {}
}) => (
    <Modal
        title={alertTitle}
        centered
        open={visible}
        onOk={() => onSuccess(false)}
        onCancel={() => onCancel(false)}
        okText={okText}
        bodyStyle={{ maxWidth: '95%' }}
        cancelText={cancelText}
        maskClosable={canClose}
        destroyOnClose
    >
    <div style={{ maxWidth: '95%' }}>
        {bodyContent}
    </div>
    </Modal>
);

export default AlertModal;