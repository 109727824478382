import { Card, Checkbox, Col, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { toNumber } from 'lodash';
import { COMPOUND_WALL_TYPES, EXTRA_GATE_TYPES, MAIN_GATE_TYPES } from '../../../constants';

const QuotationCompoundWall = ({
    modifyConstructionUnit,
}) => {
    const [isTicked, setIsTicked] = useState(false);
    const [compoundWall, setCompoundWall] = useState({
      compoundWallType: COMPOUND_WALL_TYPES[0].value,
      cwcMainGate: MAIN_GATE_TYPES[0].value,
      cwcExtraGate: MAIN_GATE_TYPES[0].value,
    });
  
    const onTick = () => {
      setIsTicked(!isTicked);
      if (isTicked) {
        modifyConstructionUnit('compoundWall', void 0);
      } else {
        modifyConstructionUnit('compoundWall', {
          typeOfUnit: 'compoundWall',
          details: compoundWall,
        });
      }
    };
  
    const mutateWall = (prop, value) => {
      setCompoundWall({
        ...compoundWall,
        [prop]: value,
      });
  
      modifyConstructionUnit('compoundWall', {
        typeOfUnit: 'compoundWall',
        details: compoundWall,
      });
    };
  
    return (
        <>
            <Checkbox checked={isTicked} onClick={() => onTick()}>
                <label>Need compound wall?</label>
            </Checkbox>
            {isTicked && (
                <>
                    <Card style={{padding: 4}}>
                        <label style={{ fontWeight: '600' }}>Compound Wall</label>
                        <Select
                            style={{
                                color: 'black',
                                border: '0px solid transparent',
                                width: '100%', 
                            }}
                            value={compoundWall.compoundWallType}
                            onChange={(val) => mutateWall('compoundWallType', val)}
                            placeholder="Select here"
                        >
                            {(COMPOUND_WALL_TYPES).map((option) => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Card>
                    <Card style={{padding: 4}}>
                        <label style={{ fontWeight: '600' }}>Main Gate</label>
                        <Select
                            style={{
                                color: 'black',
                                border: '0px solid transparent',
                                width: '100%',  
                            }}
                            value={compoundWall.cwcMainGate}
                            onChange={(val) => mutateWall('cwcMainGate', val)}
                            placeholder="Select here"
                        >
                            {(MAIN_GATE_TYPES).map((option) => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Card>
                    <Card style={{padding: 4}}>
                        <label style={{ fontWeight: '600' }}>Extra Gate</label>
                        <Select
                            style={{
                                color: 'black',
                                border: '0px solid transparent',
                                width: '100%', 
                            }}
                            value={compoundWall.cwcExtraGate}
                            onChange={(val) => mutateWall('cwcExtraGate', val)}
                            placeholder="Select here"
                        >
                            {(EXTRA_GATE_TYPES).map((option) => (
                                <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Card>
                </>
            )}
        </>
    );
};

export default QuotationCompoundWall;