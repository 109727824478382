export const MAX_FLOORS = 10;

export const TYPE_OF_UNITS = [
  {
    label: '1BHK',
    value: '_1bhk',
  },
  {
    label: '2BHK',
    value: '_2bhk',
  },
  {
    label: '3BHK',
    value: '_3bhk',
  },
  {
    label: '4BHK',
    value: '_4bhk',
  },
  {
    label: '5BHK',
    value: '_5bhk',
  },
  {
    label: '1 Room',
    value: 'room',
  },
  {
    label: '1RK',
    value: 'rk',
  },
  {
    label: 'Gym',
    value: 'gym',
  },
  {
    label: 'Shop',
    value: 'shop',
  },
  {
    label: 'Hall',
    value: 'hall',
  },
  {
    label: 'Balcony',
    value: 'balcony',
  },
  {
    label: 'Utility',
    value: 'utility',
  },
  {
    label: 'Parking',
    value: 'carParking',
  },
  {
    label: 'Shop Hall Mix',
    value: 'shopHallMix',
  },
];

export const NO_OF_UNITS = 5;

export const FLOOR_PACKAGES = [
  {
    label: 'Budget Package - Bangalore',
    value: 'FHC_C_P1_BNG_KA_IN',
  },
  {
    label: 'Basic Package - Bangalore',
    value: 'FHC_C_P2_BNG_KA_IN',
  },
  {
    label: 'Classic Package - Bangalore',
    value: 'FHC_C_P3_BNG_KA_IN',
  },
  {
    label: 'Premium Package - Bangalore',
    value: 'FHC_C_P4_BNG_KA_IN',
  },
  {
    label: 'Royale Package - Bangalore',
    value: 'FHC_C_P5_BNG_KA_IN',
  },
  {
    label: 'Imperia Package - Bangalore',
    value: 'FHC_C_P6_BNG_KA_IN',
  },
  {
    label:'Budget Package - Hyderabad',
    value:'FHC_C_P1_HYD_TG_IN'
  },
  {
    label: 'Basic Package - Hyderabad',
    value: 'FHC_C_P2_HYD_TG_IN',
  },
  {
    label: 'Classic Package - Hyderabad',
    value: 'FHC_C_P3_HYD_TG_IN',
  },
  {
    label: 'Premium Package - Hyderabad',
    value: 'FHC_C_P4_HYD_TG_IN',
  },
  {
    label: 'Royale Package - Hyderabad',
    value: 'FHC_C_P5_HYD_TG_IN',
  },
  {
    label: 'Imperia Package - Hyderabad',
    value: 'FHC_C_P6_HYD_TG_IN',
  },
  {
    label: 'Budget Package - Mysore',
    value: 'FHC_C_P1_MYS_KA_IN',
  },
  {
    label: 'Basic Package - Mysore',
    value: 'FHC_C_P2_MYS_KA_IN',
  },
  {
    label: 'Classic Package - Mysore',
    value: 'FHC_C_P3_MYS_KA_IN',
  },
  {
    label: 'Premium Package - Mysore',
    value: 'FHC_C_P4_MYS_KA_IN',
  },
  {
    label: 'Royale Package - Mysore',
    value: 'FHC_C_P5_MYS_KA_IN',
  },
  {
    label: 'Imperia Package - Mysore',
    value: 'FHC_C_P6_MYS_KA_IN',
  },
  {
    label: 'Regal Package (Hall) - Bangalore',
    value: 'COM_C_P1_BNG_KA_IN',
  },
  {
    label: 'Regal Pro Package (Hall) - Bangalore',
    value: 'COM_C_P2_BNG_KA_IN',
  },
  {
    label: 'Elegance Package (Shop) - Bangalore',
    value: 'COM_C_P3_BNG_KA_IN',
  },
  {
    label: 'Elegance Pro Package (Shop) - Bangalore',
    value: 'COM_C_P4_BNG_KA_IN',
  },
  {
    label: 'Regal Package (Hall) - Hyderabad',
    value: 'COM_C_P1_HYD_TG_IN',
  },
  {
    label: 'Regal Pro Package (Hall) - Hyderabad',
    value: 'COM_C_P2_HYD_TG_IN',
  },
  {
    label: 'Elegance Package (Shop) - Hyderabad',
    value: 'COM_C_P3_HYD_TG_IN',
  },
  {
    label: 'Elegance Pro Package (Shop) - Hyderabad',
    value: 'COM_C_P4_HYD_TG_IN',
  },
  {
    label: 'Regal Package (Hall) - Mysore',
    value: 'COM_C_P1_MYS_KA_IN',
  },
  {
    label: 'Regal Pro Package (Hall) - Mysore',
    value: 'COM_C_P2_MYS_KA_IN',
  },
  {
    label: 'Elegance Package (Shop) - Mysore',
    value: 'COM_C_P3_MYS_KA_IN',
  },
  {
    label: 'Elegance Pro Package (Shop) - Mysore',
    value: 'COM_C_P4_MYS_KA_IN',
  },
  {
    label: 'Bronze Package for Agro Corp',
    value: 'FHC_C_P1_AGROCORP',
  },
  {
    label: 'Silver Package for Agro Corp',
    value: 'FHC_C_P2_AGROCORP',
  },
  {
    label: 'Gold Package for Agro Corp',
    value: 'FHC_C_P3_AGROCORP',
  },
  {
    label: 'Diamond Package for Agro Corp',
    value: 'FHC_C_P4_AGROCORP',
  },
  {
    label: 'Platinum Package for Agro Corp',
    value: 'FHC_C_P5_AGROCORP',
  },
  {
    label: 'Gold Package for Upkar',
    value: 'FHC_C_P1_UPKAR',
  },
  {
    label: 'Diamond Package for Upkar',
    value: 'FHC_C_P2_UPKAR',
  },
  {
    label: 'Platinum Package for Upkar',
    value: 'FHC_C_P3_UPKAR',
  },

  {label: 'Freesia Package (Luxury) - Bangalore', value: 'LUX_C_P1_BNG_KA_IN'},
  {label: 'Dahlia Package for (Luxury) - Bangalore', value: 'LUX_C_P2_BNG_KA_IN'},
  {label: 'Magnolia Package for (Luxury) - Bangalore', value: 'LUX_C_P3_BNG_KA_IN'},
  {label: 'Freesia Package for (Luxury) - Hyderabad', value: 'LUX_C_P1_HYD_TG_IN'},
  {label: 'Dahlia Package for (Luxury) - Hyderabad', value: 'LUX_C_P2_HYD_TG_IN'},
  {label: 'Magnolia Package for (Luxury) - Hyderabad', value: 'LUX_C_P3_HYD_TG_IN'},
  {label: 'Freesia Package for (Luxury) - Mysuru', value: 'LUX_C_P1_MYS_KA_IN'},
  {label: 'Dahlia Package for (Luxury) - Mysuru', value: 'LUX_C_P2_MYS_KA_IN'},
  {label: 'Magnolia Package for (Luxury) -  Mysuru', value: 'LUX_C_P3_MYS_KA_IN'},
   {label: "Budget Plus Package for (Turnkey) - Hyderabad",value: "FHC_C_P7_HYD_TG_IN"},
   {label: "Basic Plus Package for (Turnkey) - Hyderabad",value: "FHC_C_P8_HYD_TG_IN"},
         {
            label: "Classic Plus Package (Turnkey) - Hyderabad",
            value: "FHC_C_P9_HYD_TG_IN"
        },
         {
            label: "Premium Plus Package (Turnkey) - Hyderabad",
            value: "FHC_C_P10_HYD_TG_IN"
        },
         {
            label: "Royale Plus Package (Turnkey) - Hyderabad",
            value: "FHC_C_P11_HYD_TG_IN"
        },
         {
            label: "Imperia Plus Package (Turnkey) - Hyderabad",
            value: "FHC_C_P12_HYD_TG_IN"
        },


];

export const COMPOUND_WALL_TYPES = [
  {
    label: 'Full compound wall',
    value: 'full',
  },
  {
    label: 'Customised',
    value: 'customised',
  },
];

export const MAIN_GATE_TYPES = [
  {
    label: '10ft x 5ft',
    value: '10ftx5ft',
  },
  {
    label: '20ft x 5ft',
    value: '20ftx5ft',
  },
];

export const EXTRA_GATE_TYPES = [
  {
    label: '3ft x 5ft',
    value: '3ftx5ft',
  },
  {
    label: '4ft x 5ft',
    value: '4ftx5ft',
  },
  {
    label: '10ft x 5ft',
    value: '10ftx5ft',
  },
  {
    label: '20ft x 5ft',
    value: '20ftx5ft',
  },
];
