import { Button, Input, message, Modal, Row } from "antd";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import "./styles/index.scss";
import { useSelector } from "react-redux";
import { HandleServerError } from "../../../utils/serverErrorHandler";
import CustomMap from "../../atoms/CustomMap";
import axios from 'axios'
const DefaultZoom = 10;

const Marker = (props) => {
	return (
		<img
			style={{
				width: "30px",
				height: "46px",
				position: "absolute",
				transform: "translate(-50%, -100%)",
				zIndex: 999999,
			}}
			src="/static/house-marker.jpg"
			alt=""
		/>
	);
};


const LocationPickerModal = ({ isModalVisible, hideModal, pushLocation, defaultLocation, uniqueKey, setPincodeValue }) => {
	const [location, setLocation] = useState({
		lat: -1,
		lng: -1,
		address: ""
	});
	const [placesAutocompleteAddress, setPlacesAutocompleteAddress] = useState("");
	const [pincode ,setPincode] = useState(null)
	useEffect(() => {
		if (defaultLocation) {
			setLocation({
				lat: defaultLocation.latitude,
				lng: defaultLocation.longitude,
				address: defaultLocation.address,
			});
		}
	}, [defaultLocation, uniqueKey]);

	const keys = useSelector((state) => state.keys);

	useEffect(() => {
		const script = document.createElement("script");
		script.src =
			`https://maps.googleapis.com/maps/api/js?key=${'AIzaSyAy574ZGQpTZx0BqbR4oSUL7xhC5hqqqm4'}&libraries=places`;
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	function handleChangeLocation(lat, lng) {
		setLocation({ ...location, lat: lat, lng: lng });

		let latitude = lat;
		let longitude = lng;
		if(latitude && longitude){
			axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCbb66Sa5lC4wPLmOsUrCfZJkw_M_JqxEU`)
				.then((json) => {
				  let latLngPinCode = null;
				  json.data.results.forEach((t) => {
					t.address_components.forEach((p) => {
					  if (p.types.includes("postal_code") && p.short_name) {
						latLngPinCode = p.short_name;
					  }
					});
				  });
				setPincodeValue(latLngPinCode)
				})
				.catch(err => console.log(err))
		}
	}

	const handleChange_placesAutocomplete = (address) => {
		setPlacesAutocompleteAddress(address);
	};

	const handleSelect_placesAutocomplete = (address) => {
		geocodeByAddress(address)
			.then((results) => getLatLng(results[0]))
			.then((latLng) => {
				setPlacesAutocompleteAddress(address);
				handleChangeLocation(latLng.lat, latLng.lng);
			})
			.catch((error) => {
				HandleServerError(error);
			});
	};

	return (
		<Modal
			destroyOnClose
			key={uniqueKey}
			style={{ top: 20, width: '100%' }}
			bodyStyle={{ width: '120%' }}
			title="Choose Location"
			open={isModalVisible}
			onOk={() => {}}
			onCancel={() => {
				setPlacesAutocompleteAddress('');
				hideModal();
			}}
			footer={[
				<Button
					key="submit"
					type="primary"
					onClick={() => {
						if (!location.address || location.address.trim() === "") {
							message.error("Plot Address cannot be empty!");
							return;
						}
						pushLocation({
							address: location.address,
							latitude: location.lat,
							longitude: location.lng
						}, pincode);
						hideModal();
					}}
				>
					Add
				</Button>,
			]}
		>
			<Row>
				<div style={{ width: "95%" }}>
					<PlacesAutocomplete
						key={uniqueKey}
						value={placesAutocompleteAddress}
						onChange={handleChange_placesAutocomplete}
						onSelect={handleSelect_placesAutocomplete}
					>
						{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
							<div>
								<input
									{...getInputProps({
										placeholder: "Search Places ...",
										className: "location-search-input",
									})}
								/>
								<div className="autocomplete-dropdown-container">
									{loading && <div>Loading...</div>}
									{suggestions.map((suggestion) => {
										const className = suggestion.active
											? "suggestion-item--active"
											: "suggestion-item";
										// inline style for demonstration purpose
										const style = suggestion.active
											? {
													backgroundColor: "#fafafa",
													cursor: "pointer",
											  }
											: {
													backgroundColor: "#ffffff",
													cursor: "pointer",
											  };
										return (
											<div
												{...getSuggestionItemProps(suggestion, {
													className,
													style,
												})}
											>
												<span>{suggestion.description}</span>
											</div>
										);
									})}
								</div>
							</div>
						)}
					</PlacesAutocomplete>
				</div>
			</Row>
			<Row>
				<div style={{ width: "100%", height: "400px" }}>
					<CustomMap
						API_KEY={'AIzaSyAy574ZGQpTZx0BqbR4oSUL7xhC5hqqqm4'}
						center={location}
						zoom={10}
					/>
				</div>
			</Row>
			<Row style={{ marginTop: "20px" }}>
				<div style={{ width: "100%" }}>
					<Input.TextArea
						key={uniqueKey}
						placeholder="Enter Plot Address"
						className="modal-form-textarea"
						defaultValue={location.address}
						required
						onChange={(e) => {
							e.stopPropagation();
							setLocation({
								...location,
								address: e.target.value,
							});
						}}
					></Input.TextArea>
				</div>
			</Row>
		</Modal>
	);
};

export default LocationPickerModal;
