import React from 'react';
import { Avatar, Card, Skeleton } from 'antd';
import './styles.scss';

const CustomCard = ({
    children,
    cardTag,
    cardTitle = '',
    cardStyle = {},
    metaStyle = {},
    loading = false,
    cardActions = [],
    metaAvtarUrl = '',
    cardDescription = '',
    avtarRequired = false,
    skeletonActive = true,
}) => {
    const { Meta } = Card;
    const getAvatar = () => {
        return metaAvtarUrl ? {
            avatar: (<Avatar src={metaAvtarUrl} />)
        } : {}
    }
    return (
        <Card
            actions={[
                ...cardActions,
            ]}
            style={{
                ...cardStyle,
            }}
            className='custom-card'
        >
            <Skeleton loading={loading} avatar={avtarRequired} active={skeletonActive}>
            <Meta
                {...getAvatar()}
                style={{
                    ...metaStyle,
                }}
                className='card-meta'
                title={cardTitle}
                description={cardDescription}
            />
            {children}
            </Skeleton>
        </Card>
    )
};

export default CustomCard;