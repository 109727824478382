import { Checkbox, Col, Input, InputNumber, Row } from 'antd';
import React, { useState } from 'react';
import { toNumber } from 'lodash';

const QuotationBasement = ({
    modifyConstructionUnit,
}) => {
    const [isTicked, setIsTicked] = useState(false);
    const [basementArea, setBasementArea] = useState(0);
  
    const onTick = () => {
      setIsTicked(!isTicked);
    };
  
    const setBasementAreaInQuotation = (unitArea) => {
      setBasementArea(unitArea);
  
      modifyConstructionUnit('basement', {
        typeOfUnit: 'basement',
        details: {unitArea},
      });
    };
  
    return (
        <>
            <Row>
                <Col>
                
                    <Checkbox checked={isTicked} onClick={() => onTick()}>
                    <label>Need basement?</label>
                    </Checkbox>
                </Col>
            </Row>
            <Row>
                <Col>
                    {isTicked && (
                        <InputNumber
                            style={{ width: '100%' }}
                            value={basementArea.toString()}
                            type="number"
                            onChange={(value) => setBasementAreaInQuotation(value)}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default QuotationBasement;