import { Checkbox, Col, Row, Select } from 'antd';
import React, { useState } from 'react';
import { FLOOR_PACKAGES, MAX_FLOORS } from '../../../constants';

const SamePackage = ({
    cityPackages = [],
    setSamePackage,
    mutateProperty,
}) => {
    const [isTicked, setIsTicked] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(
        cityPackages && cityPackages[0] ? cityPackages[0].packageUniqueId : null
    );
  
    const onTick = () => {
      if (!isTicked) {
        setPackage(selectedPackage);
      }
      mutateProperty('samePackage', !isTicked);
      setIsTicked(!isTicked);
    };
  
    const setPackage = (value) => {
      setSamePackage(value);
      setSelectedPackage(value);
      mutateProperty('mainPackage', value);
    };
  
    return (
        <>
            <div>
                <div>
                    <Checkbox checked={isTicked} onClick={() => onTick()}>
                        <label>Same package across all floors?</label>
                    </Checkbox>

                {isTicked && (
                    <Select
                        style={{
                            color: 'black',
                            border: '0px solid transparent',
                            width: '100%', 
                        }}
                        value={selectedPackage}
                        onChange={(val) => setPackage(val)}
                        placeholder="Select here"
                    >
                        {(cityPackages).map((option) => (
                            <Select.Option key={option.packageUniqueId} value={option.packageUniqueId}>
                                {option.packageDescription}
                            </Select.Option>
                        ))}
                    </Select>
                )}
                </div>
            </div>
        </>
    );
};

export default SamePackage;