export const TASK_TYPES_MAP = {
    MEETING_FOLLOWUP_OFFICE: 'Followup Meeting at BnB Office',
    MEETING_FOLLOW_UP_PLOT_LOCATION: 'Followup Meeting at Plot Location',
    MEETING_FOLLOWUP_CUSTOMER_LOCATION: 'Followup Meeting at Customer Location',
    MEETING_CUSTOMER_SITELOCATION: 'Meeting Customer at Customer Location',
    MEETING_CUSTOMER_OFFICE: 'Meeting Customer at office',
    MEETING_PLOT_LOCATION: 'Meeting Customer at Plot Location',
    VIDEO_CALL_CUSTOMER: 'Video Call with Customer',
    FOLLOW_UP_MEETING_IN_OFFICE: 'Followup Meeting at BnB Office',
    FOLLOW_UP_MEETING_AT_CUSTOMER: 'Followup Meeting at Plot Location',
    MEETING_AT_CUSTOMER_LOCATION: 'Followup Meeting at Customer Location',
    CUSTOMER_EDUCATION_MEETING: 'Customer Education Meeting',
    SCHEDULE_SITE_VISIT: 'Site Visit'
  };

export const LOCATION_QUESTION_TYPES = [
  "MEETING_FOLLOWUP_CUSTOMER_LOCATION",
  "MEETING_PLOT_LOCATION"
]