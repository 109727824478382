import React, {useState} from 'react';
import { Input, Modal, Select } from 'antd';
import { TASK_TYPES_MAP } from '../../../constants';
import './style.scss';

const cancellationReasons = [
  'Customer location not received till meeting',
  "Customer didn't pick the call",
  'Customer cancelled the meeting',
  'OS Not able to Reach',
  "OS didn't reach or not on time",
  'Meeting was not scheduled.',
  'Customer rescheduled the meeting',
  'Others',
  'SD',
];

const CancellationModal = ({
    meeting = {},
    visible,
    canClose = false,
    onSubmit = () => {},
    onCancel = () => {},
    onError,
    onSuccess,
}) => {
    const [reason, setReason] = useState('');
    const [otherReason, setOtherReason] = useState('');
    const handleCancelSubmit = () => {
        if (!reason) {
          onError('Please select a reason');
          return;
        } else if (reason === 'Others' && !otherReason) {
          onError('Please enter reason');
          return;
        }
        const finalReason =
          reason === 'Others' ? `${reason}-${otherReason}` : reason;
          onSubmit(meeting.meetingId, finalReason);
      };
    return (
        <Modal
            centered
            open={visible}
            okText="Cancel Meeting"
            onOk={() => handleCancelSubmit()}
            onCancel={() => onCancel(false)}
            maskClosable={canClose}
            destroyOnClose
        >
        <div>
            <label>Cancel the {TASK_TYPES_MAP[meeting.typeOfMeeting]}?</label>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0px' }}>
                <label>Select reason</label>
                <Select
                    style={{
                        color: 'black',
                        border: '0px solid transparent',
                        width: '100%',  
                    }}
                    showSearch
                    onChange={(val) => setReason(val)}
                    placeholder="Select reason here"
                >
                    {cancellationReasons.map((option) => (
                        <Select.Option key={option} value={option}>
                            {option}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', margin: '10px 0px' }}>
                <label>Add Comments</label>
                <Input.TextArea placeholder='Comment here' value={otherReason} bordered className='reasons-textarea' onChange={(e) => setOtherReason(e.target.value)} />
            </div>
        </div>
        </Modal>
    );
}

export default CancellationModal;