/* eslint-disable import/prefer-default-export */

import { message } from "antd";
import { PERMISSION_REQUIRED_DESC, SESSION_EXPIRED_DESC, TRY_AGAIN_DESC } from "../constants/httpErrorMessages";
import {
	HTTP_BAD_REQUEST,
	HTTP_FORBIDDEN,
	HTTP_GATEWAY_TIMEOUT,
	HTTP_INTERNAL_SERVER_ERROR,
	HTTP_NOT_FOUND,
	HTTP_UNAUTHORIZED,
} from "../redux/middlewares/httpStatusCodes";


export const HandleServerError = (error) => {
  if (error.response) {
    if (error.response.status === HTTP_NOT_FOUND) {
      if (error.response.data && error.response.data.errorUserMessage) {
        message.error(error.response.data.errorUserMessage);
      } else {
        message.error(TRY_AGAIN_DESC);
      }
    } else if (error.response.status === HTTP_UNAUTHORIZED) {
    //   if (onUnAuthorized) {
    //     if (error.response.data && error.response.data.errorUserMessage) {
    //       message.error(error.response.data.errorUserMessage);
    //     } else {
    //       message.error(SESSION_EXPIRED_DESC)
	// 	//   ,
    //     //     [
    //     //       {
    //     //         text: 'Cancel',
    //     //         style: 'cancel',
    //     //       },
    //     //       {
    //     //         text: 'OK',
    //     //         onPress: async () => onUnAuthorized(),
    //     //       },
    //     //     ],
    //     //     {cancelable: true},
    //     //   );
    //     }
    //   } else {
        if (error.response.data && error.response.data.errorUserMessage) {
          message.error(error.response.data.errorUserMessage);
        } else {
          message.error('Authentication failed!');
        }
    //   }
    } else if (error.response.status === HTTP_FORBIDDEN) {
      if (error.response.data && error.response.data.errorUserMessage) {
        message.error(error.response.data.errorUserMessage);
      } else {
        message.error(PERMISSION_REQUIRED_DESC);
      }
    } else if (error.response.status === HTTP_GATEWAY_TIMEOUT) {
      if (error.response.data && error.response.data.errorUserMessage) {
        message.error(error.response.data.errorUserMessage);
      } else {
        message.error(TRY_AGAIN_DESC);
      }
    } else if (error.response.status === HTTP_BAD_REQUEST) {
      if (error.response.data && error.response.data.errorUserMessage) {
        message.error(error.response.data.errorUserMessage);
      } else {
        message.error(TRY_AGAIN_DESC);
      }
    } else {
      if (error.response.data && error.response.data.errorUserMessage) {
        message.error(error.response.data.errorUserMessage);
      } else {
        message.error(TRY_AGAIN_DESC);
      }
    }
  } else if (error.request) {
    message.error(error.message);
  } else {
  }
};
