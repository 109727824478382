import React, { useState, useEffect } from 'react'
import './styles.scss';
import { isEmpty } from 'lodash';

function PreparedCheckList(props) {

    const { checkListData } = props;

    return (
        <div style={{ marginTop: '-1rem' }}>
            {
                checkListData?.map((item) => {
                    return (
                        <div style={{ border: '1px solid rgb(225 209 204)', borderRadius: '16px', padding: '1rem', margin: '1rem 0rem' }}>

                            <div>
                                <p style={{ fontWeight: 'bold', color: 'black', marginBottom: '0px', marginTop: '-0.4rem' }}>{item?.text}</p>
                                <p style={{ color: '#7a6a6a', marginBottom: '0rem', overflowWrap: "break-word" }}>{item?.answer}</p>
                            </div>

                            {
                                !isEmpty(item?.recommendationList) &&
                                <>
                                    <hr style={{ border: '1px solid rgb(225 209 204)' }} />
                                    <div>
                                        <p style={{ fontWeight: 'bold', color: 'black', marginBottom: '0.1rem' }}>Recommendation</p>
                                        {
                                            item?.recommendationList?.map((recommend,index) => {
                                                return <p style={{ color: '#7a6a6a', margin: index==0?'0rem 0rem 0.1rem 0rem': '0.1rem 0rem' }}>
                                                    {recommend?.recommendation}
                                                </p>
                                            })
                                        }
                                    </div>
                                </>
                            }

                            {!isEmpty(item?.useFullLinkList) &&
                                <>
                                    <hr style={{ border: '1px solid rgb(225 209 204)' }} />
                                    <div>
                                        <p style={{ fontWeight: 'bold', color: 'black', marginBottom: '0px',overflowWrap: "break-word"}}>Useful Links</p>
                                        {
                                            item?.useFullLinkList?.map((urlData,index) => {
                                                return <div style={{ color: '#7a6a6a', marginBottom: '0px' }}>
                                                    <p style={{ marginBottom: '0px', margin: index==0?'0rem 0rem 0.1rem 0rem': '0.1rem 0rem' }}>
                                                        <a href={urlData?.useFullLink}>{urlData?.useFullLinkText}</a>
                                                    </p>
                                                </div>
                                            })
                                        }
                                    </div>
                                </>
                            }

                        </div>
                    )
                })
            }
        </div>
    )
}

export default PreparedCheckList