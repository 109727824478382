import React from 'react';
import './style.scss';

const CustomPopup = ({
    popupTitle = '',
    confirmText = 'OK',
    visible = false,
    children,
    onConfirm = () => {},
}) => (
    <>
        {visible && (
            <div className='overlay-div'>
                <div className='overlay-content-wrapper'>
                    <div className='overlay-body'>
                        <h4 className='popup-title'>{popupTitle}</h4>
                        <div className='body-content'>
                            {children}
                        </div>
                        <div className='popup-footer'>
                            <div className='footer-btn-wrapper'>
                                <label className='confirm-button' onClick={onConfirm}>{confirmText}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </>
);

export default CustomPopup;