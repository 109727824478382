import { Col, Row, Select } from 'antd';
import React, { useState } from 'react';
import { MAX_FLOORS } from '../../../constants';

const QuotationNoOfFloors = ({
    setFloorInData,
}) => {
    const [noOfFloors, setNoOfFloors] = useState(1);
  
    const setFloors = (value) => {
      setNoOfFloors(value);
      setFloorInData(value);
    };
  
    return (
        <>
            <div>
                
                <div>
                    <Select
                        style={{
                            color: 'black',
                            border: '0px solid transparent',
                            width: '25%',
                        }}
                        value={noOfFloors}
                        onChange={(val) => setFloors(val)}
                        placeholder="Select here"
                    >
                        {(new Array(MAX_FLOORS).fill(0)).map((option, id) => (
                            <Select.Option key={id} value={id + 1}>
                                {(id + 1).toString()}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
        </>
    );
};

export default QuotationNoOfFloors;