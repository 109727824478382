import config from '../config';

export const isServer = !((typeof window !== 'undefined' && window.document && window.document.createElement));

export const getHostName = (onServer) => () => {
    const defaultHost = '';
    if (onServer) {
        return config.HOSTNAME || defaultHost;
    }

    return `${window.location.protocol}//${window.location.hostname}`;
};

export const withHostGetUrl = (endpoint) => {
    if (config.USE_CUSTOM_API_ENDPOINT) return config.API_ENDPOINT;
    const hostName = getHostName(isServer)();
    return `${hostName}${endpoint}`;
};

export const getOtherAppHost = (appDomain) => {
    if (config.IS_DEV_ENV) return 'http://localhost:4000'; // Assuming that the proxy server is running on 4000 port
    return getHostName(isServer)().replace('projects', appDomain);
};
